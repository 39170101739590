import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  const diagnosisCheck = (diagnosis: any) => {
    if (diagnosis && diagnosis.trim().length > 0) {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: ` `,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    } else {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: `.................................................................................................................................................................................................................`,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    }
  };
  return {
    pageOrientation: `portrait`,
    pageSize: `A4`,
    content: [
      {
        bold: `true`,
        text: `ใบรับรองแพทย์`,
        alignment: `center`,
        margin: [0, 0, 0, 0],
        decoration: ``,
        fontSize: `18`,
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        pageBreak: ``,
        text: `สำหรับ Visa Extension`,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        bold: `true`,
        decorationColor: ``,
        alignment: `center`,
        fontSize: `18`,
        margin: [0, 0, 0, 0],
        width: `auto`,
        decorationStyle: ``,
      },
      {
        bold: false,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        text: ` `,
        color: ``,
        alignment: `left`,
        decoration: ``,
      },
      {
        columns: [
          {
            width: `*`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            alignment: `right`,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            text: `วันที่`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            width: 5,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            text: props.items?.formatCreatedDate,
            width: `auto`,
            decoration: ``,
            alignment: `right`,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        text: ` `,
        fontSize: 15,
        width: `auto`,
        color: ``,
        decorationColor: ``,
        decoration: ``,
        alignment: `left`,
        bold: false,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            width: 30,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            text: `ขอรับรองว่า`,
            pageBreak: ``,
            bold: false,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientInfo?.full_name_th || props.items?.patientInfo?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `หนังสือเดินทางเลขที่`,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientPassport || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 10,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            text: ``,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            text: `สัญชาติ`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientNationalityTextTh || props.items?.patientNationalityText || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            text: `อายุ`,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: 10,
            alignment: `left`,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            text: `หมายเลขผู้ป่วย`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientInfo?.hn || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
            text: `ได้รับการตรวจรักษาในฐานะ`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.encounterInfo?.type === `OPD` ? `ผู้ป่วยนอก` : `ผู้ป่วยใน` || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `เมื่อ`,
            fontSize: 15,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientInfo?.encounter_created || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `อาการนำ :`,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            bold: false,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.chiefComplaint || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `การวินิจฉัยโรค :`,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.diagnosis.trim().length > 0 ? props.items?.diagnosis : ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `........................................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      ...diagnosisCheck(props.items?.diagnosis),
      {
        fontSize: 15,
        color: ``,
        width: `auto`,
        alignment: `left`,
        decorationColor: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        margin: [0, 0, 0, 0],
        text: ` `,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        width: `auto`,
        color: ``,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        fontSize: 15,
        text: `การรักษา / การตรวจวินิจฉัย (Treatment/Investigation)`,
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
      },
      {
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        bold: false,
        fontSize: 15,
        text: ` `,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 30,
            color: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
          },
          {
            image: props.items?.isDrug ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            width: 10,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            width: `auto`,
            text: `ให้ยา`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            width: 30,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            image: props.items?.isXray ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 10,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            fontSize: 15,
            text: `เอ็กซเรย์`,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            width: 30,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isPathology ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            text: `การตรวจวิเคราะห์ทางพยาธิวิทยา`,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            width: 30,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            image: props.items?.isBandage ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            width: 10,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            text: `ทำแผล`,
            color: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 30,
            text: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
          {
            image: props.items?.isPhysicalTherapy ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 10,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            text: `กายภาพบำบัด`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            width: 30,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
          },
          {
            image: props.items?.isProcedure ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            width: 10,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `ทำหัตถการ  กรุณาระบุ`,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isProcedureNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            width: 30,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
          },
          {
            image: props.items?.isSurgery ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            width: 10,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: `ผ่าตัด  กรุณาระบุ`,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isSurgeryNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...........................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            text: ``,
            color: ``,
            decorationStyle: ``,
            width: 30,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isOther ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            alignment: `left`,
            width: 10,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            text: `อื่น ๆ   กรุณาระบุ`,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            decorationColor: ``,
            color: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isOtherNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..........................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        bold: false,
        margin: [0, 0, 0, 0],
        width: `auto`,
        text: ` `,
        color: ``,
        decoration: ``,
      },
      {
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        bold: `true`,
        text: `ความเห็นของแพทย์ : ผู้ป่วยจำเป็นต้องได้รับการรักษาต่อเนื่อง`,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        width: `auto`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 30,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            text: ``,
          },
          {
            image: props.items?.treatmentDuration === "15" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: `15 วัน`,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            width: 10,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            image: props.items?.treatmentDuration === "21" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 5,
            bold: false,
            alignment: `left`,
            color: ``,
            decoration: ``,
          },
          {
            width: `auto`,
            text: `21 วัน`,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            width: 10,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
            bold: false,
          },
          {
            image: props.items?.treatmentDuration === "30" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: `30 วัน`,
            width: `auto`,
            color: ``,
          },
          {
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 10,
          },
          {
            image: props.items?.treatmentDuration === "45" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            width: 5,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            text: `45 วัน`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 10,
            decoration: ``,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            text: ``,
          },
          {
            image: props.items?.treatmentDuration === "60" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            width: 5,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            text: `60 วัน`,
            width: `auto`,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            text: ``,
            width: 10,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
          },
          {
            image: props.items?.treatmentDuration === "90" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            width: 5,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `90 วัน`,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            bold: `true`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            text: `จึงเป็นอุปสรรคต่อการเดินทาง`,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        text: ` `,
        decorationColor: ``,
        pageBreak: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
        color: ``,
        fontSize: 15,
      },
      {
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        bold: false,
        width: `auto`,
        decorationStyle: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        text: ` `,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `ลงนามแพทย์ผู้ตรวจ .............................................................`,
            decorationColor: ``,
            color: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            text: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            text: `เลขใบประกอบวิชาชีพแพทย์`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorCertNumber || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 80,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            text: `(${props.items?.doctorNameFormTh})`,
            width: 160,
            preserveLeadingSpaces: true,
            alignment: `center`,
          },
          {
            decoration: ``,
            alignment: `left`,
            text: ` `,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
