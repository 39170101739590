import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  const diagnosisCheck = (diagnosis: any) => {
    if (diagnosis && diagnosis.trim().length > 0) {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: ` `,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    } else {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: `.................................................................................................................................................................................................................`,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    }
  };
  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    pageSize: `A4`,
    content: [
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: `18`,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        text: `Medical Certificate`,
        pageBreak: ``,
        bold: `true`,
        decoration: ``,
        alignment: `center`,
        margin: [0, 0, 0, 0],
      },
      {
        color: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        bold: `true`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        fontSize: `18`,
        alignment: `center`,
        text: `For Visa Extension`,
      },
      {
        text: ` `,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        pageBreak: ``,
        decorationColor: ``,
        bold: false,
      },
      {
        columns: [
          {
            width: `*`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            alignment: `right`,
            decorationColor: ``,
            text: `Date`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            color: ``,
            width: 5,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
            text: props.items?.formatCreatedDate,
            fontSize: 15,
            pageBreak: ``,
            alignment: `right`,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        text: ` `,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
        width: `auto`,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            width: 30,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: `This is certify that`,
            width: `auto`,
            color: ``,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            bold: false,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            stack: [
              {
                pageBreak: ``,
                decorationColor: ``,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                bold: false,
                width: `auto`,
                fontSize: 15,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                text: props.items?.patientInfo?.full_name_en || props.items?.patientInfo?.full_name,
              },
              {
                bold: false,
                color: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `left`,
                decorationStyle: ``,
                text: `..................................................................................................................................................................`,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `Passport Number / Others`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientPassport || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            text: `Nationality`,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientNationalityTextEn || props.items?.patientNationalityText || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `Age`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            width: 10,
            text: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            text: `Hospital Number`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientInfo?.hn || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            decoration: ``,
            text: `has examined and treated at our hospital as an :`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  props.items?.encounterInfo?.type === `OPD`
                    ? `Out-patient`
                    : `In-patient` || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 10,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
          },
          {
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `on`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientInfo?.encounter_created || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: `Chief Complaint  :`,
            color: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.chiefComplaint || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `Diagnosis :`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            fontSize: 15,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.diagnosis.trim().length > 0 ? props.items?.diagnosis : ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..............................................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      ...diagnosisCheck(props.items?.diagnosis),
      {
        text: ` `,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        bold: false,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        text: `Treatment/Investigation`,
        bold: `true`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decoration: ``,
            width: 30,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isDrug ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 10,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `Medication`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 30,
          },
          {
            image: props.items?.isXray ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            width: 10,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: `X-ray`,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 30,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isPathology ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 10,
            color: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            fontSize: 15,
            text: `Laboratory`,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            width: 30,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            image: props.items?.isBandage ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decoration: ``,
            width: 10,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            text: `Wound dressing`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            width: 30,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            image: props.items?.isPhysicalTherapy ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 10,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            color: ``,
            text: ``,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            text: `Physical therapy`,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            color: ``,
            text: ``,
            width: 30,
            fontSize: 15,
          },
          {
            image: props.items?.isProcedure ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: 10,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `Procedure, please indicate`,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isProcedureNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `........................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            width: 30,
            decorationColor: ``,
          },
          {
            image: props.items?.isSurgery ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: `Surgery, please indicate`,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isSurgeryNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            text: ``,
            width: 30,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
          },
          {
            image: props.items?.isOther ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            bold: false,
            color: ``,
          },
          {
            alignment: `left`,
            bold: false,
            text: `Others, please indicate`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.isOtherNote || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        decoration: ``,
        alignment: `left`,
        color: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        decorationStyle: ``,
      },
      {
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        text: `Physician’s recommendations : Patient needs continuing medical treatment for`,
        bold: `true`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        color: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            width: 25,
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
          },
          {
            image: props.items?.treatmentDuration === "15" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 5,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            alignment: `left`,
            bold: false,
            text: `15 days`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
          },
          {
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 10,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            image: props.items?.treatmentDuration === "21" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            width: 5,
            color: ``,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            text: `21 days`,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            width: 10,
            decorationStyle: ``,
          },
          {
            image: props.items?.treatmentDuration === "30" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            color: ``,
            pageBreak: ``,
            width: 5,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `30 days`,
            bold: false,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            decoration: ``,
          },
          {
            image: props.items?.treatmentDuration === "45" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            width: 5,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: `45 days`,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
          {
            width: 10,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            image: props.items?.treatmentDuration === "60" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            text: `60 days`,
          },
          {
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: 10,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            image: props.items?.treatmentDuration === "90" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: 5,
            bold: false,
          },
          {
            text: `90 days`,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            width: 10,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: `true`,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `Not fit for travel`,
            color: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        text: ` `,
        width: `auto`,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        bold: false,
        decorationColor: ``,
      },
      {
        alignment: `left`,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        preserveLeadingSpaces: true,
        width: `auto`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            text: `Physician’s Signature .............................................................`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
          },
          {
            color: ``,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            width: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            color: ``,
            text: `Medical License No.`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorCertNumber || ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..............................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            decoration: ``,
            width: 97,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            text: `(${props.items?.doctorNameFormEn})`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            width: 150,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
    ],
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
