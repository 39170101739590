import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Dropdown,
  Input,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardMedCertificateReportUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1em"}}>
        {props.onLoading}
        <div
          style={{fontWeight: "bold", fontSize: "large"}}>
          ใบรับรองแพทย์
        </div>
        <hr>
        </hr>
        <div
          style={{display: "flex"}}>
          
          <div
            style={{flex: "1"}}>
            
            <Form>
              <FormGroup
                className="ui form"
                style={{padding: "1em 0em 0em 0.5em", gap: "2em"}}>
                <label
                  style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
                  ประเภทเอกสาร
                </label>
                <Dropdown
                  clearable={true}
                  disabled={props.disabledReportOption || false}
                  id="CardMedCertificateReport-Dropdown-ReportType"
                  onChange={props.onHandleReport}
                  options={props.reportOption}
                  placeholder="เลือกประเภทเอกสาร"
                  selection={true}
                  style={{width: "22em"}}
                  value={props.onReportValue}>
                </Dropdown>
              </FormGroup>
            </Form>
            <div>
              {props.reportTypeSelect}
            </div>
            <div>
              {props.onSaveReport}
            </div>
          </div>
          <div
            style={{flex: "1"}}>
            
            <Form>
              <FormGroup
                className="ui form"
                style={{padding: "1em 0em 0em 0em", justifyContent: "flex-end", gap: "1em"}}>
                <label
                  style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
                  From
                </label>
                <DateTextBox
                  onChange={(date: string) => props.onHandleDateNew("startDate", date)}
                  value={props.startDate}>
                </DateTextBox>
                <label
                  style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
                  To
                </label>
                <DateTextBox
                  onChange={(date: string) => props.onHandleDateNew("endDate", date)}
                  value={props.endDate}>
                </DateTextBox>
              </FormGroup>
              <FormGroup
                className="ui form"
                style={{padding: "0em 0em 2em 0em", justifyContent: "flex-end", gap: "1em"}}>
                <label
                  style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
                  แพทย์
                </label>
                <Input
                  icon="search"
                  id="CardMedCertificateReport-Input-SearchDoctor"
                  onChange={(e) => props.onHandleDoctorName("doctorName", e)}
                  placeholder="เว้นว่างเพื่อหาทุกแพทย์"
                  value={props.onDoctorName}>
                </Input>
                <Button
                  color="blue"
                  loading={props.searchReportLoading}
                  onClick={props.onSearchReport}>
                  ค้นหา
                </Button>
              </FormGroup>
            </Form>
            <Table
              data={props.dataTable}
              getTrProps={props.rowProps}
              headers={`วันที่-เวลาที่พิมพ์,รหัส,ประเภทเอกสาร,ภาษา,ผู้พิมพ์,สำเนา`}
              keys={props.keyTable}
              minRows="10"
              showPagination={false}
              widths="150, 80, 130,50, auto,70">
            </Table>
            <div>
              {props.onDuplicateReport}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardMedCertificateReportUX

export const screenPropsDefault = {}

/* Date Time : Mon Sep 09 2024 17:07:02 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onLoading"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบรับรองแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"large\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 58,
      "void": true
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Form",
      "parent": 60,
      "props": {
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reportTypeSelect"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Form",
      "parent": 61,
      "props": {
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 0em\", justifyContent: \"flex-end\", gap: \"1em\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0em 0em 2em 0em\", justifyContent: \"flex-end\", gap: \"1em\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormGroup",
      "parent": 62,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 0.5em\", gap: \"2em\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทเอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledReportOption || false"
        },
        "id": {
          "type": "value",
          "value": "CardMedCertificateReport-Dropdown-ReportType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleReport"
        },
        "options": {
          "type": "code",
          "value": "props.reportOption"
        },
        "placeholder": {
          "type": "value",
          "value": "เลือกประเภทเอกสาร"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onReportValue"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "From"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "To"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 80,
      "name": "DateTextBox",
      "parent": 65,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleDateNew(\"startDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 65,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleDateNew(\"endDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 66,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardMedCertificateReport-Input-SearchDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleDoctorName(\"doctorName\", e)"
        },
        "placeholder": {
          "type": "value",
          "value": "เว้นว่างเพื่อหาทุกแพทย์"
        },
        "value": {
          "type": "code",
          "value": "props.onDoctorName"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searchReportLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchReport"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 85,
      "name": "Table",
      "parent": 61,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataTable"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "`วันที่-เวลาที่พิมพ์,รหัส,ประเภทเอกสาร,ภาษา,ผู้พิมพ์,สำเนา`"
        },
        "keys": {
          "type": "code",
          "value": "props.keyTable"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "150, 80, 130,50, auto,70"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onSaveReport"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onDuplicateReport"
        }
      },
      "seq": 86,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardMedCertificateReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
