import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
  useCallback,
  CSSProperties,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Label,
  Select,
  Icon,
  Modal,
  TextArea,
  Header,
  Checkbox,
  Popup,
} from "semantic-ui-react";

import moment from "moment";

import Tab from "react-lib/frameworks/Tab";

// UX
import CardPatientListUX from "./CardPatientListUX";
import CardPharmacyTask from "react-lib/apps/HISV3/TPD/CardPharmacyTask";
import CardLabOrderQueue from "react-lib/apps/HISV3/LAB/CardLabOrderQueue";
import CardADM from "react-lib/apps/HISV3/ADM/CardADM";
import CardReceiveOrder from "react-lib/apps/HISV3/PTM/CardReceiveOrder";
import CardWardQueue from "react-lib/apps/HISV3/ADM/CardWardQueue";
import CardORQueue from "react-lib/apps/HISV3/ORM/CardORQueue";
import MedAdminQueue from "react-lib/apps/HISV3/TPD/MedAdminQueue";
import MSDMenuUXMED from "react-lib/apps/HISV3/MSD/dispensing/MSDMenuUX";
import CardSupplyOrderQueueList from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderQueueList";
import CardSupplyDeliveryQueue from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryQueue";
import CardSupplyDeliveryManager from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryManager";
import CardRefillDivisionPrint from "react-lib/apps/HISV3/MSD/dispensing/CardRefillDivisionPrint";
import CardBilling from "react-lib/apps/HISV3/BIL/CardBilling";
import CardSupplyOrderHistory from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderHistory";
import CardSupplyDeliveryHistory from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryHistory";
import CardMedRequestReturn from "react-lib/apps/HISV3/MSD/dispensing/CardMedRequestReturn";
import CardReportQueue from "react-lib/apps/HISV3/ADM/CardReportQueue";
import CardRejectOrder from "react-lib/apps/HISV3/common/CardRejectOrder";
import CardImagingWorkList, {
  CardImagingWorkListRef,
} from "react-lib/apps/HISV3/IME/CardImagingWorkList";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";

import CardPHVWrapper from "HIS/PHV/CardPHVWrapper";
import CardDoctorPendingTask from "react-lib/apps/HISV3/DPO/CardDoctorPendingTask";
import CardConsultationList from "react-lib/apps/HISV3/DPO/CardConsultationList";
import CardConsultCancelDetail from "react-lib/apps/HISV3/DPO/CardConsultCancelDetail";
import CardStockManagement from "../TPD/CardStockManagement";
import SubDoctorOrderDetail from "./SubDoctorOrderDetail";
import CardStockTransferOrder from "../TPD/CardStockTransferOrder";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SearchBox from "react-lib/appcon/common/SearchBox";

// Utils
import { formatDate, formatDateToStringBe, formatDateToYYYYMMDD } from "react-lib/utils/dateUtils";
import { useInterval } from "react-lib/utils/hooksUtils";

// CONFIG
import CONFIG from "config/config";

// Types

const POLLING_PATIENT_QUEUE = 1000 * 60;

enum DT { // Division Type
  "หน่วยตรวจ" = "หน่วยตรวจ",
  "หอผู้ป่วย" = "หอผู้ป่วย",
  "ห้องผ่าตัด" = "ห้องผ่าตัด",
  "พื้นที่ดูแล" = "พื้นที่ดูแล",
  "คลินิกพรีเมี่ยม" = "คลินิกพรีเมี่ยม",
  "หอผู้ป่วยพรีเมี่ยม" = "หอผู้ป่วยพรีเมี่ยม",
  "ทั่วไป" = "ทั่วไป",
  "การเงิน" = "การเงิน",
  "ห้องยา" = "ห้องยา",
  "ห้องเวชภัณฑ์" = "ห้องเวชภัณฑ์",
  "แผนกเก็บ Specimen" = "แผนกเก็บ Specimen",
  "หน่วยย่อย" = "หน่วยย่อย",
  "short stay" = "short stay",
  "backoffice" = "backoffice",
  "องค์กร" = "องค์กร",
  "จังหวัด" = "จังหวัด",
}

enum RT { // Role Type
  "TECHNICIAN" = "TECHNICIAN",
  "REGISTERED_NURSE" = "REGISTERED_NURSE",
  "DOCTOR" = "DOCTOR",
  "GENERAL" = "GENERAL",
  "PHARMACIST" = "PHARMACIST",
  "ADMIT_STAFF" = "ADMIT_STAFF",
  "TECHNICIAN_PMR" = "TECHNICIAN_PMR",
  "NUTRITIONISTS" = "NUTRITIONISTS",
  "PEDIATRIC_DENTISTS" = "PEDIATRIC_DENTISTS",
}

enum DC { // Division Code
  "XRAY" = "XRAY",
}

const DNAll = Object.values(DT);
const RTAll = Object.values(RT)
const DCAll = Object.values(DC)


const DEFAULT_MENU_BUTTON_SEQUENCE = [
  {name: "xray_queue", title: "Imaging worklist" },
  {name: "queue", title: "Patient Queue" },
  {name: "ward_queue", title: "Patient Queue" },
  {name: "receive", title: "Receive & Return ยา/เวชภัณฑ์" },
  {name: "med_admin", title: "Med Admin" },
  {name: "verbal_order", title: "Verbal order" },
  {name: "reject_order", title: "คำสั่งรอการแก้ไข" },
  {name: "phv", title: "Patient History Viewer" },
  {name: "or_queue", title: "OR Queue" },
  {name: "orschedule", title: "OR Schedule" },
  {name: "specimen_management", title: "Specimen Management"},
  {name: "report_order", title: "รายงาน" },
  {name: "consultation", title: "Consultation list" },
  {name: "stock_management", title: "Stock Management" },
]

const ENABLE_MENU_BY_DIVISION_TYPE: Record<string, any[]> = {
  "queue": [DT.หน่วยตรวจ, DT.พื้นที่ดูแล, DT.คลินิกพรีเมี่ยม],
  "ward_queue": [DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม],
  "med_admin": [ DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม, DT.ห้องผ่าตัด ],
  "verbal_order": [DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม],
  "reject_order": [DT.หน่วยตรวจ, DT.คลินิกพรีเมี่ยม],
  "phv": [ DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.ห้องผ่าตัด, DT.พื้นที่ดูแล, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม],
  "or_queue": [DT.ห้องผ่าตัด],
  "orschedule": [DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม],
  "specimen_management": [ DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.พื้นที่ดูแล, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม, DT.ห้องผ่าตัด ],
  "report_order": [ DT.หอผู้ป่วย, DT.หน่วยตรวจ, DT.ห้องผ่าตัด, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม ],
  "consultation": [DT.หอผู้ป่วย, DT.หน่วยตรวจ, DT.ห้องผ่าตัด, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม],
  "stock_management": [DT.หอผู้ป่วย, DT.หน่วยตรวจ, DT.ห้องผ่าตัด],
}

const ENABLE_MENU_BY_DIVISION_CODE: Record<string, string[]> = {
  "xray_queue": [DC.XRAY],
}

const DISABLE_MENU_BY_DIVISION_CODE: Record<string, string[]> = {
  // "queue": [DC.XRAY],
  "ward_queue": [DC.XRAY],
  "receive": [DC.XRAY],
  "med_admin": [DC.XRAY],
  "verbal_order": [DC.XRAY],
  "reject_order": [DC.XRAY],
  "phv": [DC.XRAY],
  "or_queue": [DC.XRAY],
  "orschedule": [DC.XRAY],
  "specimen_management": [DC.XRAY],
  "report_order": [DC.XRAY],
  "consultation": [DC.XRAY],
}

// false คือ ถ้า isDoctor === false จะ แสดง
const ENABLE_MENU_BY_IS_DOCTOR: Record<string, boolean> = {
  "med_admin": false,
  "stock_management": false
}

const ENABLE_MENU_BY_ROLE_TYPE: Record<string, string[]> = {
  "orschedule" : [RT.REGISTERED_NURSE],
  "specimen_management": [RT.TECHNICIAN, RT.REGISTERED_NURSE],
  "stock_management": Object.values(RT).filter((role) => role !== RT.DOCTOR),
}



const CardPatientList = React.forwardRef<any, any>((props, ref) => {
  console.log("Init CardPatientList props: ", props);

  const [tab, setTab] = useState("");
  const [tabMSD, setTabMSD] = useState("queueOrder");
  const [hn, setHn] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [division, setDivision] = useState();
  const [doctor, setDoctor] = useState("");
  const [status, setStatus] = useState("");
  const [classify, setClassify] = useState("");
  const [previousTab, setPreviousTab] = useState<string>("");
  const [messageTemplate, setMessageTemplate] = useState<any>(null);
  const [chatMessage, setChatMessage] = useState<any>("");
  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [start_date, setStart_date] = useState(formatDateToStringBe(moment()));
  const [end_date, setEnd_date] = useState(formatDateToStringBe(moment()));
  const [editEncounter, setEditEncounter] = useState<number | null>(null);
  const [modEditEN, setModEditEN] = useState<{ en: number | null } | null>(null);
  const [includeStudent, setIncludeStudent] = useState<boolean>(false);

  const [classifyOptions, setClassifyOptions] = useState<any[]>([]);
  const [openModConsultCancelDetail, setOpenModConsultCancelDetail] = useState<any>({
    open: false,
    item: null,
  });

  const textareaRef = useRef<any>();
  const isTextareaChange = useRef<boolean>(false);
  const touchCountRef = useRef(0);
  const touchTimerRef = useRef<any>(null);
  const timestampSearchRef = useRef<any>(moment());
  const cardImagingWorkListRef = useRef<CardImagingWorkListRef>();
  const cardDrugOrderQueueRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    refreshLabOrderQueue: handlePrepareGetLabOrderQueue,
    refreshImagingWorkList: cardImagingWorkListRef.current?.refresh,
    refreshDrugOrderQueue: cardDrugOrderQueueRef.current?.refresh,
  }));

  useInterval(() => {
    const diff = moment().diff(timestampSearchRef.current, "milliseconds");
    const half = POLLING_PATIENT_QUEUE / 2;

    if (diff >= half && tab === "queue") {
      handleSearchQueue();
    }
  }, POLLING_PATIENT_QUEUE);

  useEffect(() => {
    props?.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["division", {}],
          ["doctor", {}],
          ["EncounterStatus", {}],
          ["patientCase", {}],
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (props.selectedDivision?.organization?.code) {
      props.onEvent({
        message: "GetDivisionByOrganization",
        params: {
          organization : props.selectedDivision?.organization?.id,
          limit: 99999
        },
      });
    }
  },[props.selectedDivision?.organization])

  const handleGetLabOrderQueue = useCallback(
    async (params: Record<string, any> = {}) => {
      await props.setProp(`labOrderQueue`, {
        ...props.labOrderQueue,
        ...params,
        checkSpecimen: false,
        checkSpecimenReject: false,
        selectedDivision: props.division,
      });

      //Specimen Management
      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "search",
        },
      });
    },
    [props.labOrderQueue, props.division]
  );

  useEffect(() => {
    props.onEvent({
      message: "GetClassify",
      params: { division: props.divisionId },
    });

    if (
      [DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(
        props.divisionType
      )
    ) {
      handleGetLabOrderQueue();
    }

    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    let doctorDetail = doctor;
    let divisionId = props.divisionId;

    if (isDoctor && props.currentDoctor) {
      setDoctor(props.currentDoctor);

      doctorDetail = props.currentDoctor;
      divisionId = "";
    } else {
      setDivision(divisionId);
    }

    handleSearchQueue({ division: divisionId, doctor: doctorDetail });
  }, [props.divisionId]);

  useEffect(() => {

    if (Array.isArray(props.classifyList)) {
      setClassifyOptions(
        (props.classifyList || [])?.map((item: any) => ({
          key: item.triage_level,
          value: item.triage_level,
          text: item.description,
        }))
      );
    } else {
      setClassifyOptions([])
    }

  }, [props.classifyList]);

  useEffect(() => {
    if (props.selectedTabPatientList) {
      return setTab(props.selectedTabPatientList);
    }

    if ([DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType || "")) {
      setTab("ward_queue");
    } else if (props.selectedDivision?.code === "XRAY") {
      setTab("xray_queue");
    } else if ([DT.หน่วยตรวจ, DT.คลินิกพรีเมี่ยม].includes(props.divisionType || "")) {
      setTab("queue");
    } else if ([DT.ห้องผ่าตัด].includes(props.divisionType || "")) {
      setTab("or_queue");
    } else {
      setTab("queue");
    }
  }, [props.selectedDivision]);

  useEffect(() => {
    if (tab && tab !== props.selectedTabPatientList) {
      props.setProp("selectedTabPatientList", tab);
    }
  }, [tab]);

  // -useEffect(() => {
  //   console.log("saika tab ", tab, props.divisionType, previousTab);
  //   if (tab === "" && [DN.หอผู้ป่วย].includes(props.divisionType || "")) {
  //     setTab(previousTab);
  //   }
  // }, [tab]);

  useEffect(() => {
    if (isTextareaChange.current) {
      textareaRef.current?.ref?.current?.focus();
    }
  }, [chatMessage]);

  const handlePrepareGetLabOrderQueue = () => {
    const params: Record<string, any> = {};
    const currentDate = formatDate(moment());
    const startDate = props.labOrderQueue?.selectedStartDate || "";
    const endDate = props.labOrderQueue?.selectedEndDate || "";

    const date = formatDateToYYYYMMDD(currentDate);

    if (startDate && formatDateToYYYYMMDD(startDate) > date) {
      params.selectedStartDate = currentDate;
    }
    if (endDate && formatDateToYYYYMMDD(endDate) < date) {
      params.selectedEndDate = currentDate;
    }

    setTimeout(() => {
      handleGetLabOrderQueue(params);
    }, 500);
  };



  // -const pharmacyList = useMemo(() => {
  //   return (props.encounterList || []).map((en: any) => {
  //     return {
  //       ...en,
  //       encounter: en.number,
  //       triage_level:
  //         en.triage_level === "1" ? (
  //           <Label color="violet">{en.triage_level}</Label>
  //         ) : en.triage_level === "2" ? (
  //           <Label color="red">{en.triage_level}</Label>
  //         ) : en.triage_level === "3" ? (
  //           <Label color="orange">{en.triage_level}</Label>
  //         ) : en.triage_level === "4" ? (
  //           <Label color="yellow">{en.triage_level}</Label>
  //         ) : en.triage_level === "5" ? (
  //           <Label color="green">{en.triage_level}</Label>
  //         ) : (
  //           <Label>-</Label>
  //         ),
  //     };
  //   });
  // }, [props.encounterList]);

  const splitSequencePatientName = (patientName: string) => {
    let newPatientName = patientName?.split("(0)");

    if (newPatientName.length === 1) {
      return newPatientName;
    } else {
      return newPatientName?.[0];
    }
  };

  const calculateWaitingTime = (encounter: any): string => {
    let waitingTime: string = "";

    const statusesToExclude = ["IN_QUEUE", "IN_EXAM", "CHECKED_OUT", "WAIT_PAY", "DISCHARGED"];

    if (!statusesToExclude.includes(encounter.status)) {
      const enStart: moment.Moment = moment(encounter.started, "YYYY-MM-DD HH:mm");
      const now: moment.Moment = moment();

      const diffTime = now.diff(enStart);
      const duration = moment.duration(diffTime);
      const hours: number = duration.hours();
      const minutes: number = duration.minutes();

      if (hours > 0) {
        waitingTime += hours + " ชั่วโมง ";
      }
      if (minutes > 0) {
        waitingTime += minutes + " นาที";
      }
    }

    return waitingTime;
  };

  const handleSearchQueue = (params: any = {}) => {
    timestampSearchRef.current = moment();

    props.onEvent({
      message: "GetPatientQueue",
      params: {
        hn: hn,
        first_name: firstName,
        last_name: lastName,
        division: division,
        doctor: doctor,
        include_student: includeStudent,
        start_date: start_date,
        end_date: end_date,
        ...params,
        ...(CONFIG.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE
          ? { triage_level: classify }
          : { status: status }),
      },
    });
  };

  const handleCloseDoctorOrderDetail = useCallback(
    (orderType) => {
      if (orderType === "drugopdhomeorder") {
        handleSearchQueue();
      }
    },
    [handleSearchQueue]
  );

  const encounterList = useMemo(() => {
    let userData: any[] = [];

    if (props.providerEmployeeInfo?.employee_info?.position === 3) {
      userData = props.encounterList.filter(
        (item: any) => item.division === props.selectedDivision?.id
      );
    } else {
      userData = props.encounterList;
    }

    const sortedList = userData.sort((a: any, b: any) => {
      if (a.triage_level === "" || b.triage_level === "") {
        return a.triage_level === "" ? 1 : -1;
      }

      return a.triage_level.localeCompare(b.triage_level);
    });

    return sortedList.map((encounter: any) => {
      const triageLevelColors = {
        "1": "violet",
        "2": "red",
        "3": "orange",
        "4": "yellow",
        "5": "green",
      } as any;

      const documentStatusColors = {
        D: "red",
        C: "green",
      } as any;

      const documentStatusChar = {
        D: "I",
        C: "C",
      } as any;

      const DOCUMENT_STATUS = {
        COLOR: {
          Draft: "red",
          Confirm: "green",
        } as any,
        LABEL: {
          Draft: "I",
          Confirm: "C",
        } as any,
        DESC: {
          Draft: "Incomplete",
          Confirm: "Completed",
        } as any,
      };

      let document_tips = "";
      if (encounter.document_info.status !== "") {
        document_tips = "<b>" + DOCUMENT_STATUS.DESC[encounter.document_info.status] + "</b><br/>";
        for (const [key, value] of Object.entries(encounter.document_info.items)) {
          document_tips += key + ": " + value + "<br/>";
        }
      }

      return {
        ...encounter,
        hn_encounter: (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {`${encounter.hn} (${encounter.number})`}
              {props.masterOptions?.patientCase
                ?.find((item: any) => item.value === encounter.patient_case)
                ?.text?.toLowerCase() === "telemed" && (
                <Label circular color="yellow" style={{ height: "1.8rem", width: "1.8rem" }}>
                  <Icon name="phone" style={{ transform: "rotate(90deg)" }} />
                </Label>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon
                name="edit"
                color="yellow"
                onClick={(e: any) => {
                  e.stopPropagation();

                  props.setProp("selectedPatient", { id: encounter.patient });

                  setModEditEN({ en: encounter.id });
                  setEditEncounter(null);
                }}
              />
            </div>
          </div>
        ),
        patient_full_name: (
          <div
            style={
              encounter.can_chat
                ? {
                    display: "grid",
                    gridTemplateColumns: "1fr 35px",
                  }
                : {}
            }
          >
            <label>{splitSequencePatientName(encounter.patient_name)}</label>
            {!!encounter.can_chat && (
              <div style={{ position: "relative" }}>
                <img
                  aria-hidden="true"
                  src={"/static/images/order_images/telemed.png"}
                  style={{
                    width: "28px",
                    height: "28px",
                    cursor: "pointer",
                    marginRight: "4px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleSelectMessage(null);
                    setSelectedChat({
                      chat_channel: encounter.chat_channel,
                      division: encounter.division,
                    });
                  }}
                />
                {!!encounter.unread_message_count && (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: "red",
                      position: "absolute",
                      borderRadius: "50%",
                      top: 1,
                      right: 9,
                    }}
                  ></div>
                )}
              </div>
            )}
          </div>
        ),
        triage_level: (
          <Label color={triageLevelColors[encounter.triage_level]}>
            {encounter.triage_level || "-"}
          </Label>
        ),
        waiting_time: calculateWaitingTime(encounter),
        admit_code: (props.encounterDetailDict?.admitorderfrom?.[encounter.id] || [])
          .map((order: any) => order.code)
          .join(", "),
        document: (
          <>
            {encounter.document_info.status !== "" && (
              <Popup
                trigger={
                  <Label
                    basic
                    color={documentStatusColors[encounter.document_info?.status.charAt(0)]}
                  >
                    {documentStatusChar[encounter.document_info?.status.charAt(0)] || ""}
                  </Label>
                }
                content={<div dangerouslySetInnerHTML={{ __html: document_tips }} />}
                position="bottom left"
              />
            )}
          </>
        ),
        doctor_order: (
          <div>
            {encounter.need_approve && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <img src={"/static/images/order_images/need_approve.png"} />
                {` NEED APPROVE`}
              </div>
            )}
            <div style={{ display: "grid", gridTemplateColumns: "40% 40%" }}>
              <SubDoctorOrderDetail
                onEvent={props.onEvent}
                setProp={props.setProp}
                // data
                data={encounter}
                // options
                masterOptions={props.masterOptions}
                onClose={handleCloseDoctorOrderDetail}
              />
            </div>
          </div>
        ),
        classify_name: classifyOptions.find((item: any) => item.key === encounter.triage_level)
          ?.text,
        edit_encounter: (
          <div>
            <div>
              {`${encounter?.doctor_name}  ${
                encounter?.student && encounter?.student !== null ? " /" : ""
              }`}
            </div>
            {encounter?.student ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/static/images/order_images/student.png"}
                  style={{ width: "1.3em", height: "1.3em", marginRight: "5px" }}
                />
                {encounter?.student_name}
              </div>
            ) : null}
          </div>
        ),
        status_name: (
          <div style={{ display: "flex" }}>
            <div>{encounter?.status_name}</div>
            <div
              style={{
                ...(!encounter?.notification_doctor_queue_sent && {
                  display: "none",
                }),
              }}
            >
              <img
                style={{ marginLeft: "3px" }}
                src={"/static/images/order_images/notification_doctor_queue_sent.png"}
              />
            </div>
          </div>
        ),
      };
    });
  }, [
    props.encounterList,
    props.encounterDetailDict,
    props.masterOptions?.patientCase,
    editEncounter,
    handleCloseDoctorOrderDetail,
  ]);

  const isDoctor = useMemo(() => {
    return props.django?.user?.role_types?.includes("DOCTOR");
  }, [props.django]);

  const handleSelectMessage = (e: any, v: any = null) => {
    const value = v?.value || props.chatMessageTemplateOptions?.[0]?.value || null;

    setMessageTemplate(value);
    setChatMessage(
      props.chatMessageTemplateOptions?.find((item: any) => item.value === value)?.message || ""
    );
  };

  const handleSendMessage = () => {
    props.onEvent({
      message: "postChatChannelMessage",
      params: {
        apiToken: props.apiToken,
        chatChannelId: selectedChat?.chat_channel,
        content: chatMessage,
        contentFile: chatMessage,
        contentType: "text",
        divisionId: selectedChat?.division,
      },
    });

    setSelectedChat(null);
  };

  const openEncounter = (encounter: any) => {
    console.log('openEncounter encounter: ', encounter);
    props.onEvent({
      message: "SelectEncounter",
      params: { encounter: encounter || null },
    });
    props?.forward();
  }

  const handleDoubleClick = (rowInfo: any) => {
    console.log("handleDoubleClick row :", rowInfo?.original);
    // Remove details from selected encounter to get original pure encounter from api
    const { admit_code, ...encounter } = rowInfo?.original;
    console.log('handleDoubleClick encounter: ', encounter);
    // ใช้ 3 อย่าง
    // encounter.patient_id
    // encounter.id
    // encounter.type
    openEncounter(encounter)
    // props.onEvent({
    //   message: "SelectEncounter",
    //   params: { encounter: encounter || null },
    // });
    // props?.forward();
  };


  console.log(" CardPatientList props:", props)
  console.log(" CardPatientList state: ", { tab, tabMSD, hn, firstName, lastName, division, doctor, status, classify, previousTab, messageTemplate, chatMessage, selectedChat, start_date, end_date, editEncounter, modEditEN, includeStudent })

  useEffect(() => {
    if (props.patientQueueEncounter && division && props.loadingStatus === false) {
      console.log('openEncounter: ', props.patientQueueEncounter);

      openEncounter(props.patientQueueEncounter)
      props.setProp("patientQueueEncounter", null)

    }
  }, [props.patientQueueEncounter, division, props.loadingStatus])

  const handleTouchStart = (rowInfo: any) => {
    touchCountRef.current++;

    if (touchCountRef.current === 1) {
      touchTimerRef.current = setTimeout(function () {
        touchCountRef.current = 0;
      }, 300);
    } else if (touchCountRef.current === 2) {
      clearTimeout(touchTimerRef.current);
      touchCountRef.current = 0;

      handleDoubleClick(rowInfo);
    }
  };

  const handleCloseModEditEN = () => {
    setModEditEN(null);
    props.setProp("selectedPatient", null);
  };

  useEffect(() => {
    const updateStatus = async () => {
      await props.setProp(`labNotify`, { rejection: false, request: false });

      for (const item of props.labOrderQueue?.labOrderQueueList || []) {
        if (item.status_name === "ร้องขอทำ Lab") {
          await props.setProp(`labNotify.request`, true);
        } else if (item.status_name === "Specimen ถูกปฏิเสธ" || item.order_item_have_rejected) {
          await props.setProp(`labNotify.rejection`, true);
        }
      }
    };

    updateStatus();
  }, [props.labOrderQueue?.labOrderQueueList, props.division_id]);

  // console.log("encounterList: ", encounterList);
  // console.log("CardPatientList props.divisionType: ", props.divisionType);
  // console.log("CardPatientList props.django?.user?.role_types: ", props.django?.user?.role_types);
  // console.log("props.searchedItemList", props.searchedItemList);
  // console.log("doctor", doctor);

  /// Logic Show / Hide


  //

  // const DOCTOR_OPD = ["examination", "queue", ]
  // const DOCTOR_IPD = ["ward", "queue", ]
  // const DOCTOR_OPD_IPD = ["examination", ""]
  // // ...

  // const DEFAULT_REGISTER = ["ward", "queue"]

  return props.selectedDivision?.code === "XRAY" && false ? (
    <div
      id="CardPatientList"
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "15px 15px 0 10px",
        }}
      >
        <> Not </>
      </div>
    </div>
  ) : [
    DT.หน่วยตรวจ,
    DT.หอผู้ป่วย,
    DT.ห้องผ่าตัด,
    DT.พื้นที่ดูแล,
    DT.คลินิกพรีเมี่ยม,
    DT.หอผู้ป่วยพรีเมี่ยม,
  ].includes(props.divisionType || "") ? (
    props.size === "max" ? (
      <div
        id="CardPatientList"
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "15px 15px 0 10px",
          }}
        >

          { DEFAULT_MENU_BUTTON_SEQUENCE.filter((menu: any) => {

            // console.log("checking menu", menu)

            // Combile not Common
            // Menu ที่ไม่ใช่ logic ( and รวมกัน )
            // "recieved"
            if (menu.name === "receive") {
              if (
                ([DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(
                  props.divisionType
                ) &&
                  !isDoctor &&
                  ![DC.XRAY].includes(props.selectedDivision?.code)) ||
                ([DT.ห้องผ่าตัด].includes(props.divisionType) &&
                  ![DC.XRAY].includes(props.selectedDivision?.code))
              ) {
                // console.log("show receive")
                return true;
              }
            }

            // Combile Not Common
            // Feature 68373 OR Schedule
            if (menu.name === "orschedule") {
              console.log("CardPatientList check orschedule")
              if ( props.django?.user?.role_types?.includes(RT.REGISTERED_NURSE) && [DT.หน่วยตรวจ, DT.ห้องผ่าตัด, DT.ห้องยา, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType)) {
                return true
              }
              if ( props.django?.user?.role_types?.includes(RT.DOCTOR) && [DT.ห้องผ่าตัด, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType)) {
                return true
              }
              if ( props.django?.user?.role_types?.includes(RT.ADMIT_STAFF) && [DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType)) {
                return true
              }
              if (props.django?.user?.role_types?.includes(RT.PHARMACIST) && ![DT.หน่วยตรวจ].includes(props.divisionType)) {
                return true
              }
              return false
            }
            // --------
            // Common ENABLE
            // --------
            // props.divisionType
            // ถ้ามี key ใน list ให้ดูว่า อยู่ใน array ถ้าไม่ใช่ ไม่แสดง ถ้าไม่มี key ก็ ถือว่า แสดง
            let listDTKey = Object.keys(ENABLE_MENU_BY_DIVISION_TYPE)
            if (listDTKey.some((name: string) => { return name === menu.name }) ) {
              if (!ENABLE_MENU_BY_DIVISION_TYPE[menu.name].includes(props.divisionType)) {
                // console.log("hide ", menu.name)
                return false
              }
            }

            // props.selectedDivision?.code
            // ถ้ามี key ใน list ให้ดูว่า อยู่ใน array ถ้าไม่ใช่ ไม่แสดง ถ้าไม่มี key ก็ ถือว่า แสดง
            let listDCKey = Object.keys(ENABLE_MENU_BY_DIVISION_CODE)
            if (listDCKey.some((name: string) => { return name === menu.name }) ) {
              if (!ENABLE_MENU_BY_DIVISION_CODE[menu.name].includes(props.selectedDivision?.code)) {
                // console.log("hide ", menu.name)
                return false
              }
            }

            // isDoctor
            // ถ้ามี key ใน list ให้ดูว่า isdoctor เป็น ตาม boolean ใน list ก็ ถือว่า แสดง
            let listBDKey = Object.keys(ENABLE_MENU_BY_IS_DOCTOR)
            if (listBDKey.some((name: string) => { return name === menu.name}) ) {
              if (ENABLE_MENU_BY_IS_DOCTOR[menu.name] !== isDoctor) {
                // console.log("hide ", menu.name)
                return false
              }
            }

            // props.django?.user?.role_types
            // ถ้ามี key ใน list ให้ดูว่า อยู่ใน array ถ้าไม่ใช่ ไม่แสดง ถ้าไม่มี key ก็ ถือว่า แสดง
            let listRTKey = Object.keys(ENABLE_MENU_BY_ROLE_TYPE)
            if (listRTKey.some((name: string) => { return name === menu.name}) ) {
              if (!ENABLE_MENU_BY_ROLE_TYPE[menu.name].some((item) => props.django?.user?.role_types.includes(item))) {
                // console.log("hide ", menu.name)
                return false
              }
            }

            // Common DISABLE
            // ถ้ามี key ใน list ให้ดูว่า อยู่ใน array ถ้าไม่ใช่ ไม่แสดง ถ้าไม่มี key ก็ ถือว่า แสดง
            let listDDCKey = Object.keys(DISABLE_MENU_BY_DIVISION_CODE)
            if (listDDCKey.some((name: string) => { return name === menu.name }) ) {
              if (DISABLE_MENU_BY_DIVISION_CODE[menu.name].includes(props.selectedDivision?.code)) {
                // console.log("hide ", menu.name)
                return false
              }
            }

            // กรองจาก Config
            if (CONFIG.V3_HIDDEN_TAB_LIST.includes(menu.name)) {
              // console.log("hide ", menu.name)
              return false
            }

            return true
          })


          .map((menu: any) => {
            if (menu.name === "specimen_management") {
              return <MenuItem key={menu.name} tab={tab} setTab={setTab} name={menu.name} title={
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "4px",
                    }}
                  >
                    {menu.title}
                  </div>
                  <div
                    style={{
                      display: props.labNotify.rejection ? "block" : "none",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1.5em",
                      height: "1.5em",
                      margin: "0px 5px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: props.labNotify.request ? "block" : "none",
                      backgroundColor: "yellow",
                      borderRadius: "50%",
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  ></div>
                </div>
              } />
            }
            return <MenuItem key={menu.name} tab={tab} setTab={setTab} name={menu.name} title={menu.title} />
          })}
          {/* {props.selectedDivision?.code === "XRAY" ? (
            <MenuItem tab={tab} name="xray_queue" title="Imaging worklist" setTab={setTab} />
          ) : null} */}

          {/* {[DT.หน่วยตรวจ, DT.พื้นที่ดูแล, DT.คลินิกพรีเมี่ยม].includes(props.divisionType) &&
            !CONFIG.V3_HIDDEN_TAB_LIST.includes("queue") && (
              <MenuItem tab={tab} name="queue" title="Patient Queue" setTab={setTab} />
            )} */}
          {/* {[DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType) && (
            <MenuItem tab={tab} name="ward_queue" title="Patient Queue" setTab={setTab} />
          )} */}
          {/* {(([DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(
            props.divisionType
          ) &&
            !isDoctor) ||
            [DT.ห้องผ่าตัด].includes(props.divisionType)) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("receive") && (
                <MenuItem
                  tab={tab}
                  name="receive"
                  title="Receive & Return ยา/เวชภัณฑ์"
                  setTab={setTab}
                />
              )}
            </>
          )} */}
          {/* {[
            DT.หน่วยตรวจ,
            DT.หอผู้ป่วย,
            DT.คลินิกพรีเมี่ยม,
            DT.หอผู้ป่วยพรีเมี่ยม,
            DT.ห้องผ่าตัด,
          ].includes(props.divisionType) &&
            !isDoctor && (
              <>
                {!CONFIG.V3_HIDDEN_TAB_LIST.includes("med_admin") && (
                  <MenuItem tab={tab} name="med_admin" title="Med Admin" setTab={setTab} />
                )}
              </>
            )} */}
          {/* {[DT.หน่วยตรวจ, DT.หอผู้ป่วย, DT.คลินิกพรีเมี่ยม, DT.หอผู้ป่วยพรีเมี่ยม].includes(
            props.divisionType
          ) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("verbal_order") && (
                <MenuItem tab={tab} name="verbal_order" title="Verbal order" setTab={setTab} />
              )}
            </>
          )} */}
          {/* {[DT.หน่วยตรวจ, DT.คลินิกพรีเมี่ยม].includes(props.divisionType) &&
            !CONFIG.V3_HIDDEN_TAB_LIST.includes("reject_order") && (
              <MenuItem tab={tab} name="reject_order" title="คำสั่งรอการแก้ไข" setTab={setTab} />
            )} */}
          {/* {[
            DT.หน่วยตรวจ,
            DT.หอผู้ป่วย,
            DT.ห้องผ่าตัด,
            DT.พื้นที่ดูแล,
            DT.คลินิกพรีเมี่ยม,
            DT.หอผู้ป่วยพรีเมี่ยม,
          ].includes(props.divisionType) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("phv") && (
                <MenuItem tab={tab} name="phv" title="Patient History Viewer" setTab={setTab} />
              )}
            </>
          )} */}
          {/* show OR Queue when division ward and role nurse */}
          {/* {[DT.ห้องผ่าตัด].includes(props.divisionType) && (
            <MenuItem tab={tab} name="or_queue" title="OR Queue" setTab={setTab} />
          )} */}

          {/* {[DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType) &&
            props.django?.user?.role_types?.includes("REGISTERED_NURSE") && (
              <MenuItem tab={tab} name="orschedule" title="OR Schedule" setTab={setTab} />
          )} */}
          {/* {[
            DT.หน่วยตรวจ,
            DT.หอผู้ป่วย,
            DT.พื้นที่ดูแล,
            DT.คลินิกพรีเมี่ยม,
            DT.หอผู้ป่วยพรีเมี่ยม,
          ].includes(props.divisionType) &&
            props.django?.user?.role_types?.find((role: string) =>
              [RT.TECHNICIAN, RT.REGISTERED_NURSE].includes(role as any)
            ) && (
              <>
                {!CONFIG.V3_HIDDEN_TAB_LIST.includes("specimen_management") && (
                  <MenuItem
                    tab={tab}
                    name="specimen_management"
                    title={
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "4px",
                          }}
                        >
                          Specimen Management
                        </div>
                        <div
                          style={{
                            display: props.labNotify.rejection ? "block" : "none",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "1.5em",
                            height: "1.5em",
                            margin: "0px 5px",
                          }}
                        ></div>
                        <div
                          style={{
                            display: props.labNotify.request ? "block" : "none",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        ></div>
                      </div>
                    }
                    setTab={setTab}
                  />
                )}
              </>
            )} */}
          {/* {[
            DT.หอผู้ป่วย,
            DT.หน่วยตรวจ,
            DT.ห้องผ่าตัด,
            DT.คลินิกพรีเมี่ยม,
            DT.หอผู้ป่วยพรีเมี่ยม,
          ].includes(props.divisionType) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("report_order") && (
                <MenuItem tab={tab} name="report_order" title="รายงาน" setTab={setTab} />
              )}
            </>
          )} */}
          {/* {[
            DT.หอผู้ป่วย,
            DT.หน่วยตรวจ,
            DT.ห้องผ่าตัด,
            DT.คลินิกพรีเมี่ยม,
            DT.หอผู้ป่วยพรีเมี่ยม,
          ].includes(props.divisionType) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("consultation") && (
                <MenuItem tab={tab} name="consultation" title="Consultation list" setTab={setTab} />
              )}
            </>
          )} */}
        </div>

        {tab === "xray_queue" ? (
          <CardImagingWorkList
            onEvent={props.onEvent}
            setProp={props.setProp}
            ref={cardImagingWorkListRef}
            // seq
            runSequence={props.runSequence}
            ImagingWorkListSequence={props.ImagingWorkListSequence}
            // data
            django={props.django}
            doctorDetail={props.currentDoctor}
            // CommonInterface
            searchedItemListWithKey={props.searchedItemListWithKey}
            buttonLoadCheck={props.buttonLoadCheck}
            userTokenize={props.userTokenize}
            modXrayDetail={props.modXrayDetail}
            errorMessage={props.errorMessage}
            // options
            masterOptions={props.masterOptions}
            // callback
            forward={props.forward}
            // ----- Dental Diagram
            clinicalFindingId={props.clinicalFindingId}
            clinicalFindingIndex={props.clinicalFindingIndex}
            clinicalFindings={props.clinicalFindingList || []}
            organ={props.organ}
            clinicaltags={props.clinicaltags}
            filterClinicalFindingIds={props.filterClinicalFindingIds || []}
            // -----#
          />
        ) : null}

        {tab === "ward_queue" ? (
          <CardWardQueue
            onEvent={props.onEvent}
            layout={props.layout}
            forward={props?.forward}
            setProp={props.setProp}
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            drugOrderQueueController={props.drugOrderQueueController}
            // data
            WardQueueSequence={props.WardQueueSequence}
            AdmitChangeSequence={props.AdmitChangeSequence}
            SetBedSequence={props.SetBedSequence}
            modAdmit={props.modAdmit}
            intraTransferForm={props.intraTransferForm}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            intraHospitalTransferOptions={props.intraHospitalTransferOptions}
            loadingStatus={props.loadingStatus}
            selectVitalSignLoading={props.selectVitalSignLoading}
            getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
            encounterVitalSignList={props.encounterVitalSignList}
            django={props.django}
            division={props.division}
            // labOrderQueue={props.labOrderQueue}
            // selectedLabOrderWorking={props.selectedLabOrderWorking}
          />
        ) : tab === "queue" ? (
          <div
            style={{
              padding: "15px 10px 15px 10px",
              width: "calc(100vw - 4.5rem)",
            }}
          >
            <Form>
              <Form.Group inline widths="equal">
                <Form.Field
                  control={Input}
                  label="HN"
                  placeholder="Search..."
                  icon="search"
                  value={hn}
                  onChange={(e: any) => setHn(e.target.value)}
                  width={4}
                />
                <Form.Field
                  control={Input}
                  label="ชื่อ"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                  width={4}
                />
                <Form.Field
                  control={Input}
                  label="นามสกุล"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  width={4}
                />
                <Form.Field width={4}>
                  <label style={{ minWidth: "max-content" }}>หน่วยตรวจ</label>
                  <Select
                    value={division}
                    clearable={true}
                    search={true}
                    options={props.divisionOrganization || []}
                    fluid={true}
                    style={{ width: "80%" }}
                    onChange={(e: any, v: any) => setDivision(v.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <SearchBox
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // ref
                    inputRef={(ref: any) =>
                      ref && (ref.inputRef.current.style.minWidth = "max-content")
                    }
                    // data
                    searchedItemList={props.searchedItemList}
                    selectedItem={doctor}
                    setSelectedItem={setDoctor}
                    type="Doctor"
                    label="แพทย์"
                    toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
                    style={{ width: "100%" }}
                    fluid={true}
                    disabled={isDoctor}
                  />
                </Form.Field>

                <Form.Field
                  control={Checkbox}
                  includeStudent
                  label="รวมรายการตรวจงาน"
                  name="includeStudent"
                  onChange={(e: any, v: any) => setIncludeStudent(v.checked)}
                  value={includeStudent}
                />

                {CONFIG.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE ? (
                  <Form.Field
                    control={Select}
                    label="classify"
                    options={(props.classifyList || []).map((item: any) => ({
                      key: item.triage_level,
                      value: item.triage_level,
                      text: item.description,
                    }))}
                    onChange={(e: any, v: any) => setClassify(v.value)}
                    value={classify}
                    clearable
                    style={{ width: "90%" }}
                    fluid={true}
                    width={4}
                  />
                ) : (
                  <Form.Field
                    control={Select}
                    label="สถานะ"
                    options={props.masterOptions?.EncounterStatus || []}
                    onChange={(e: any, v: any) => setStatus(v.value)}
                    value={status}
                    clearable
                    style={{ width: "90%" }}
                    fluid={true}
                    width={4}
                  />
                )}

                <Form.Field inline>
                  <label style={{ minWidth: "max-content" }}>วันที่</label>
                  <DateTextBox
                    value={start_date}
                    style={{ width: "165px" }}
                    inputStyle={{ width: "165px" }}
                    onChange={(date: any) => setStart_date(date)}
                  ></DateTextBox>
                </Form.Field>
                <Form.Field inline>
                  <label>ถึง</label>
                  <DateTextBox
                    value={end_date}
                    style={{ width: "165px" }}
                    inputStyle={{ width: "165px" }}
                    onChange={(date: any) => setEnd_date(date)}
                  ></DateTextBox>
                </Form.Field>
                <Form.Field inline>
                  <Button
                    content="ค้นหา"
                    color="blue"
                    loading={props.loadingStatus}
                    onClick={() => handleSearchQueue()}
                  />
                </Form.Field>
              </Form.Group>
            </Form>

            <CardPatientListUX
              encounterList={encounterList}
              showDivision={isDoctor}
              showDocument={!CONFIG.RAKSTHAI_HIDE_SUPPLY_SUBMENU}
              encounterListRowProps={(state: any, rowInfo: any) => {
                return {
                  style: {
                    backgroundColor:
                      rowInfo?.original?.id && rowInfo?.original?.id === props.selectedEncounter?.id
                        ? "#cccccc"
                        : rowInfo?.original?.triage_level.props.children === "1" ||
                          rowInfo?.original?.triage_level.props.children === "2"
                        ? "#fc8486"
                        : rowInfo?.original?.triage_level.props.children === "3"
                        ? "#feffa9"
                        : "white",
                  },

                  // edit encounter
                  // onClick: () => {
                  //   // setTimeout in Single Click
                  //   debounceTimeoutRef.current = setTimeout(() => {
                  //     clickCountRef.current += 1;
                  //     if (editEncounter === rowInfo?.original?.id) {
                  //       setEditEncounter(null);
                  //     } else {
                  //       props.onEvent({
                  //         message: "GetPatientDetailView",
                  //         params: {
                  //           patient: rowInfo?.original?.patient,
                  //         },
                  //       });
                  //       setEditEncounter(rowInfo?.original?.id);
                  //     }
                  //   }, 300);
                  // },

                  onTouchStart: () => handleTouchStart(rowInfo),
                  onClick: () => handleDoubleClick(rowInfo),
                };
              }}
            />

            <Modal
              open={!!modEditEN}
              size="large"
              closeOnDimmerClick={true}
              onClose={handleCloseModEditEN}
            >
              <div style={{ margin: "10px 10px 0" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem 0 -0.75rem",
                  }}
                >
                  <div>
                    <Header>แก้ไข Encounter</Header>
                  </div>
                  <div>
                    <Icon
                      name="cancel"
                      color="red"
                      size="large"
                      link={true}
                      onClick={handleCloseModEditEN}
                    />
                  </div>
                </div>
                <CardOpenEncounter
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  runSequence={props.runSequence}
                  patient={props.selectedPatient}
                  selectedPatient={props.selectedPatient}
                  CreateUpdateEncounterSequence={props.CreateUpdateEncounterSequence}
                  appointmentEncounterList={props.appointmentEncounterList}
                  selectedAppointmentItems={props.selectedAppointmentItems}
                  masterOptions={props.masterOptions}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  isEditEncounter={true}
                  editEncounterId={modEditEN?.en}
                  // style
                  cardStyle={{ paddingBottom: 0 }}
                  onUpdated={() => handleSearchQueue()}
                />
              </div>
            </Modal>

            <ModConfirm
              openModal={!!selectedChat}
              titleColor="blue"
              titleName="ส่งข้อความ"
              basic={false}
              approveButtonText="OK"
              approveButtonColor="teal"
              denyButtonText="Cancel"
              denyButtonColor="grey"
              onDeny={() => setSelectedChat(null)}
              onApprove={handleSendMessage}
              onCloseWithDimmerClick={() => setSelectedChat(null)}
              content={
                <>
                  <Form>
                    <Dropdown
                      value={messageTemplate}
                      selection={true}
                      options={props.chatMessageTemplateOptions || []}
                      fluid
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      onChange={handleSelectMessage}
                    />
                    <TextArea
                      ref={textareaRef}
                      value={chatMessage}
                      rows={5}
                      onChange={(e: any, v) => {
                        isTextareaChange.current = true;
                        setChatMessage(v.value);
                      }}
                    />
                  </Form>
                </>
              }
            />
          </div>
        ) : tab === "or_queue" ? (
          <CardORQueue
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            forward={props.forward}
            // common
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // seq
            runSequence={props.runSequence}
            ORQueueSequence={props.ORQueueSequence}
            ZoneLogSequence={props.ZoneLogSequence}
            ORRequestSequence={props.ORRequestSequence}
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            proxyController={props.proxyController}
            // SearchBox
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // options
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            // data
            selectedOrOrder={props.selectedOrOrder}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
            divisionId={props.divisionId}
            config={{
              showFilterORQueue: true,
              showFilterDrugQueue: false,
            }}
          />
        ) : tab === "orschedule" ? (
          <>
            <CardORQueue
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              forward={props.forward}
              // common
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              // seq
              runSequence={props.runSequence}
              ORQueueSequence={props.ORQueueSequence}
              ZoneLogSequence={props.ZoneLogSequence}
              ORRequestSequence={props.ORRequestSequence}
              // controller
              drugOrderQueueController={props.drugOrderQueueController}
              proxyController={props.proxyController}
              // SearchBox
              searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              // options
              masterOptions={props.masterOptions}
              masterData={props.masterData}
              // data
              selectedOrOrder={props.selectedOrOrder}
              loadingStatus={props.loadingStatus}
              userTokenize={props.userTokenize}
              divisionId={props.divisionId}
              config={{
                showFilterORQueue: false,
                showFilterDrugQueue: true,
              }}
              cardStyle={{ margin: "0 -10px" }}
            />
          </>
        ) : tab === "receive" ? (
          <CardReceiveOrder
            runSequence={props.runSequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            django={props.django}
            isDoctor={isDoctor}
            isPharmacist={props.isPharmacist}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedDivision={props.selectedDivision}
            drugOrderQueue={props.drugOrderQueue}
            drugOrder={props.drugOrder}
            drugOrderLog={props.drugOrderLog}
            modNoteReject={props.modNoteReject}
            drugPermission={props.drugPermission}
            buttonLoadCheck={props.buttonLoadCheck}
            layout={props.layout}
            forward={props.forward}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedPatient={props.selectedPatient}
            selectedDrug={props.selectedDrug}
            drugDose={props.drugDose}
            drugDelivery={props.drugDelivery}
            modConfirmAction={props.modConfirmAction}
            modDrugRecommendation={props.modDrugRecommendation}
            modDrugInteraction={props.modDrugInteraction}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            medErrorWorking={props.MedErrorListSequence?.medErrorWorking}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            successMessage={props.successMessage}
            // DrugTransferRequest
            drugTransferRequestSequence={props.DrugTransferRequestSequence}
            drugOrderHistoryController={props.drugOrderHistoryController}
            drugRequestList={props.drugRequestList}
            selectedDevice={props.selectedDevice}
            drugTransferRequestlist={props.drugTransferRequestlist}
            drugTransferLog={props.drugTransferLog}
            drugTransferRequestDetail={props.drugTransferRequestDetail}
            ReceiveOrderSequence={props.ReceiveOrderSequence}
            EncounterReceive={props.EncounterReceive}
            ChoiceBarcode={props.ChoiceBarcode}
            //ReturnOrder
            ReturnOrderSequence={props.ReturnOrderSequence}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            DrugOrderActionSequence={props.DrugOrderActionSequence}
            DispensingOrderSupplySequence={props.DispensingOrderSupplySequence}
            ReturnSupplySequence={props.ReturnSupplySequence}
            SupplyDeliverySequence={props.SupplyDeliverySequence}
            supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
            selectedDrugOrderWorking={props.selectedDrugOrderWorking}
            // Allergy
            AllergySequence={props.AllergySequence}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // TODO: Check is need MedReconcile Alert modal
            // divisionType={props.divisionType}
            // medReconcileIndex={props.medReconcileIndex}
            // django={props.django}
          />
        ) : tab === "med_admin" ? (
          <MedAdminQueue
            // controller
            patientPanelController={props.patientPanelController}
            proxyController={props.proxyController}
            // data
            patientInfo={props.patientInfo}
            medicationRecord={props.medicationRecord}
            medRecordSummary={props.medRecordSummary}
            django={props.django}
            division={props.divisionList}
            wardTypeList={props.wardTypeList}
            tabCurrentIndex={props.tabCurrentIndex}
            modDrugInteraction={props.modDrugInteraction}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            currentDoctor={props.currentDoctor}
            masterData={props.masterData}
            // callback
            onEvent={props.onEvent}
            runSequence={props.runSequence}
            setProp={props.setProp}
          />
        ) : tab === "verbal_order" ? (
          <CardDoctorPendingTask
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            // callback
            onEvent={props.onEvent}
            setProp={props.setProp}
            runSequence={props.runSequence}
            // data
            masterOptions={props.masterOptions}
            DoctorPendingTaskSequence={props.DoctorPendingTaskSequence}
            loadingStatus={props.loadingStatus}
            errorMessage={props.errorMessage}
          />
        ) : tab === "phv" ? (
          <div style={{ paddingTop: "1rem", height: "90vh", overflowY: "auto" }}>
            <CardPHVWrapper
              apiToken={props.apiToken}
              division={props.division}
              django={props.django}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // controller
              proxyController={props.proxyController}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              // options
              masterOptions={props.masterOptions}
              masterData={props.masterData}
              ChoiceTriage={props.ChoiceTriage}
              // seq
              runSequence={props.runSequence}
              AssessmentSequence={props.AssessmentSequence}
              HistoryCentralLabSequence={props.HistoryCentralLabSequence}
              ORPostOperationSequence={props.ORPostOperationSequence}
              ORHistorySequence={props.ORHistorySequence}
              ORRequestSequence={props.ORRequestSequence}
              PerioperativeNursingSequence={props.PerioperativeNursingSequence}
              PreOperationSequence={props.PreOperationSequence}
              // SearchBox
              searchedItemListWithKey={props.searchedItemListWithKey}
              // data
              selectedPatient={props.selectedPatient}
              loadingStatus={props.loadingStatus}
              userTokenize={props.userTokenize}
              // Imaging Result
              ImagingResultSequence={props.ImagingResultSequence}
              // CommonInterface
              modXrayDetail={props.modXrayDetail}
              // ----- Dental Diagram
              clinicalFindingId={props.clinicalFindingId}
              clinicalFindingIndex={props.clinicalFindingIndex}
              clinicalFindingList={props.clinicalFindingList || []}
              organ={props.organ}
              clinicaltags={props.clinicaltags}
              filterClinicalFindingIds={props.filterClinicalFindingIds || []}
              // -----#
              notRenameTitle={true}
            />
          </div>
        ) : tab === "reject_order" ? (
          <>
            <CardRejectOrder
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              layout={props.layout}
              forward={props?.forward}
              drugOrderQueueController={props.drugOrderQueueController}
              RejectOrderSequence={props.RejectOrderSequence}
              AllergySequence={props.AllergySequence}
              patientADR={props.patientADR}
              disabledVerbalMedOrder={props.disabledVerbalMedOrder}
              orderTypes={props.orderTypes}
              controller={props.controller}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              selectedProgressCycle={props.selectedProgressCycle}
              providerEmployeeInfo={props.providerEmployeeInfo}
              // selectedDrugItem to edit
              selectedDrugItemDetail={props.selectedDrugItemDetail}
              selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
              drugSearchText={props.drugSearchText}
              drugSearchResult={props.drugSearchResult}
              DrugSelectSequence={props.DrugSelectSequence}
              selectedDrug={props.selectedDrug}
              drugOrderList={props.drugOrderList}
              drugOrderListLoading={props.drugOrderListLoading}
              drugOrder={props.drugOrder}
              renderDrugOrderItems={props.renderDrugOrderItems}
              drugDose={props.drugDose}
              drugQty={props.drugQty}
              drugDuration={props.drugDuration}
              drugDescription={props.drugDescription}
              drugPrn={props.drugPrn}
              // modal doctor certificate check
              modDoctorCertificate={props.modDoctorCertificate}
              DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
              modConfirmDeleteDrug={props.modConfirmDeleteDrug}
              modDrugLabInteraction={props.modDrugLabInteraction}
              modDrugDisease={props.modDrugDisease}
              modDrugInteraction={props.modDrugInteraction}
              currentDoctor={props.currentDoctor}
              modReMedication={props.modReMedication}
              drugOrderItemEstimate={props.drugOrderItemEstimate}
              drugPermission={props.drugPermission}
              searchedItemList={props.searchedItemList}
              drugOrderItemMasterData={{
                masterUnits: props.masterOptions.unit,
                masterRoutes: props.masterOptions.route,
                masterSites: props.masterOptions.site,
                masterFrequencies: props.masterOptions.frequency,
                masterMethods: props.masterOptions.method,
              }}
              duplicateReasonsOptions={props.masterOptions.duplicateReasons}
              masterData={props.masterData}
              // SolventSelectionConfig
              modSolventSelectionConfig={props.modSolventSelectionConfig}
              selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
              django={props.django}
            />
          </>
        ) : tab === "specimen_management" ? (
          <CardLabOrderQueue
            setProp={props.setProp}
            onEvent={props.onEvent}
            // seq
            runSequence={props.runSequence}
            LabReportSequence={props.LabReportSequence}
            // data
            django={props.django}
            layout={props.layout}
            labOrderQueue={props.labOrderQueue}
            selectedLabOrderWorking={props.selectedLabOrderWorking}
            division={props.divisionId}
            permissionLabResultConfidential={props.permissionLabResultConfidential}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            searchedItemListWithKey={props.searchedItemListWithKey}
            successMessage={props.successMessage}
            errorMessage={props.errorMessage}
            selectedPatient={props.selectedPatient}
            // options
            masterOptions={props.masterOptions}
            // config
            size={props.size}
            tableHeight="calc(100dvh - 23rem)"
            style={{ height: "calc(100% - 3.5rem)", overflow: "auto" }}
            // callback
            forward={props?.forward}
          />
        ) : tab === "report_order" ? (
          <CardReportQueue
            controller={props.controller}
            division={props.divisionId}
            divisionType={props.divisionType}
            // sequence
            runSequence={props.runSequence}
            AnesDoctorFeeListSequence={props.AnesDoctorFeeListSequence}
            FoodListSequence={props.FoodListSequence}
            FoodRequestSequence={props.FoodRequestSequence}
            InpatientDiagnosisSequence={props.InpatientDiagnosisSequence}
            ORQueueSequence={props.ORQueueSequence}
            ZoneLogSequence={props.ZoneLogSequence}
            ORRequestSequence={props.ORRequestSequence}
            ORCancelListSequence={props.ORCancelListSequence}
            ORCaseListSequence={props.ORCaseListSequence}
            MedErrorListSequence={props.MedErrorListSequence}
            DrugResuscitationSequence={props.DrugResuscitationSequence}
            SetBedSequence={props.SetBedSequence}
            ReportPatientIPDListSequence={props.ReportPatientIPDListSequence}
            ReportPatientListSequence={props.ReportPatientListSequence}
            ReportDrugUseRecordSequence={props.ReportDrugUseRecordSequence}
            AnesthesiaStatReportSequence={props.AnesthesiaStatReportSequence}
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            proxyController={props.proxyController}
            crdAdmitOrderController={props.crdAdmitOrderController}
            subICD10DetailController={props.subICD10DetailController}
            printListController={props.printListController}
            scannedDocumentController={props.scannedDocumentController}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            forward={props.forward}
            // SearchBox
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // options
            foodRequestOptions={props.foodRequestOptions}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            // data
            selectedOrOrder={props.selectedOrOrder}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
            divisionId={props.divisionId}
            django={props.django}
            selectedDivision={props.selectedDivision}
            divisionList={props.divisionList}
            // config
            config={{
              showFilterORQueue: false,
              showFilterDrugQueue: false,
              showFilterWardQueue: true,
            }}
            eocIsLoading={props.eocIsLoading}
            //CardWardQueue
            WardQueueSequence={props.WardQueueSequence}
            AdmitChangeSequence={props.AdmitChangeSequence}
            modAdmit={props.modAdmit}
            intraTransferForm={props.intraTransferForm}
            intraHospitalTransferOptions={props.intraHospitalTransferOptions}
            selectVitalSignLoading={props.selectVitalSignLoading}
            getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
            encounterVitalSignList={props.encounterVitalSignList}
          />
        ) : tab === "consultation" ? (
          <CardConsultationList
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            django={props.django}
            currentDoctorId={props.currentDoctor?.id}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // sequence
            runSequence={props.runSequence}
            ConsultationListSequence={props.ConsultationListSequence}
            // options
            masterOptions={props.masterOptions}
            searchedItemList={props.searchedItemList}
            consultData={props.consultData}
            divisionList={props.divisionList}
            // data
            consultDetail={props.consultDetail}
            patientId={null}
            encounterId={null}
            forward={props.forward}
            isWard={[DT.หอผู้ป่วย, DT.หอผู้ป่วยพรีเมี่ยม].includes(props.divisionType)}
            showList={true}
            selectedConsultOrder={null}
          />
        ) : tab === "stock_management" ? (
          <div style={{ height: "90vh", paddingTop: "5px" }}>
            <Tab
              tabWidth="150px"
              // selectedTabIndex={0}
              data={[
                {
                  key: "การจัดการสินค้า",
                  name: "การจัดการสินค้า",
                  render: () => (
                    <CardStockManagement
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      StockManagementSequence={props.StockManagementSequence}
                      // data
                      selectedDivision={props.selectedDivision}
                      hiddenTabs={["Add"]}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      errorMessage={props.errorMessage}
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      // options
                      masterOptions={props.masterOptions}
                    />
                  ),
                },
                {
                  key: "รายการโอนสินค้า (Transfer request)",
                  name: (
                    <div
                      onClick={() => {
                        props.setProp("transferOrderCount", 0);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label>รายการโอนสินค้า (Transfer request)</label>
                      {props.transferOrderCount > 0 && (
                        <Label
                          data-testid="msg-noti"
                          circular
                          color={"red"}
                          size="mini"
                          onClick={() => {
                            props.setProp("transferOrderCount", 0);
                          }}
                        >
                          {props.transferOrderCount}
                        </Label>
                      )}
                    </div>
                  ),
                  render: () => (
                    <CardStockTransferOrder
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      StockTransferOrderSequence={props.StockTransferOrderSequence}
                      masterOptions={props.masterOptions}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      errorMessage={props.errorMessage}
                      selectedDivision={props.selectedDivision}
                    />
                  ),
                }
              ]}
            />
          </div>
        ) : tab === "history" ? null : (
          <></>
        )}

        <CardConsultCancelDetail
          onCloseMod={() => setOpenModConsultCancelDetail({ open: false, item: null })}
          isOpen={openModConsultCancelDetail?.open}
          cancel_note={openModConsultCancelDetail?.item?.cancel_note || ""}
          cancel_user={openModConsultCancelDetail?.item?.cancel_user || ""}
        />
      </div>
    ) : (
      <div>
        {encounterList?.map((encounter: any, index: number) => (
          <div
            style={{
              borderBottom: "solid #cccccc 1px",
              backgroundColor: encounter.id === props.selectedEncounter?.id ? "#cccccc" : "white",
              cursor: "pointer",
            }}
            key={index}
            onClick={() => {
              props.onEvent({
                message: "SelectEncounter",
                params: { encounter: encounter || null },
              });
            }}
            onDoubleClick={() => {
              if (props.layout === 1) {
                props.onEvent({
                  message: "SelectEncounter",
                  params: { encounter: encounter || null },
                });
                props?.forward?.();
              }
            }}
          >
            {encounter.patient_name}
            {encounter.admit_code && ` (Admit Code: ${encounter.admit_code})`}
          </div>
        ))}
      </div>
    )
  ) : props.divisionType === "ห้องยา" ? (
    <CardPharmacyTask
      drugOrderQueueRef={cardDrugOrderQueueRef}
      apiToken={props.apiToken}
      onEvent={props.onEvent}
      masterOptions={props.masterOptions}
      runSequence={props.runSequence}
      setProp={props.setProp}
      // set mode
      drugOrderTabMode={props.drugOrderTabMode}
      // sequence
      SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
      DrugOrderHistorySequence={props.DrugOrderHistorySequence}
      // drug order queue
      drugOrderQueue={props.drugOrderQueue}
      verbalDrugOrderQueue={props.verbalDrugOrderQueue}
      selectedDrugOrderWorking={props.selectedDrugOrderWorking}
      selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
      drugOrderQueueController={props.drugOrderQueueController}
      // drug order history
      drugOrderHistoryController={props.drugOrderHistoryController}
      drugHistoryList={props.drugHistoryList}
      drugHistoryOrderList={props.drugHistoryOrderList}
      layout={props.layout}
      forward={props?.forward}
      drugTransferRequestSequence={props.DrugTransferRequestSequence}
      selectedDevice={props.selectedDevice}
      drugTransferRequestlist={props.drugTransferRequestlist}
      drugTransferLog={props.drugTransferLog}
      drugOrderLog={props.drugOrderLog}
      // Medication Error List
      medErrorList={props.medErrorList}
      filterMedError={props.filterMedError}
      medErrorWorking={props.medErrorWorking}
      MedErrorListSequence={props.MedErrorListSequence}
      drugRequestList={props.drugRequestList}
      errorMessage={props.errorMessage}
      successMessage={props.successMessage}
      drugTransferRequestDetail={props.drugTransferRequestDetail}
      django={props.django}
      //DrugtransferRequestHistory
      DrugTransferRequestHistorySequence={props.DrugTransferRequestHistorySequence}
      DrugTransferRequestHistoryList={props.DrugTransferRequestHistoryList}
      //DrugReturnRequestHistory
      DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
      DrugReturnRequestHistoryList={props.DrugReturnRequestHistoryList}
      //AppointmentTelephar
      TelePharmacySequence={props.TelePharmacySequence}
      // OR Queue
      buttonLoadCheck={props.buttonLoadCheck}
      ORQueueSequence={props.ORQueueSequence}
      ZoneLogSequence={props.ZoneLogSequence}
      ORRequestSequence={props.ORRequestSequence}
      proxyController={props.proxyController}
      searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      masterData={props.masterData}
      selectedOrOrder={props.selectedOrOrder}
      loadingStatus={props.loadingStatus}
      userTokenize={props.userTokenize}
      divisionId={props.divisionId}
      userId={props.django?.user?.id || null}
      selectedDivision={props.selectedDivision}
      divisionList={props.divisionList}
      // Drug Resuscitation
      DrugResuscitationSequence={props.DrugResuscitationSequence}
      // StockManagement
      StockManagementSequence={props.StockManagementSequence}
      StockTransferOrderSequence={props.StockTransferOrderSequence}
      // StockManagement
      lotNoExpList={props.lotNoExpList}
      transferOrderCount={props.transferOrderCount}
      //DashboardTelephar
      DashboardTelepharSequence={props.DashboardTelepharSequence}
      // postponeNoti
      notificationMessageList={props.notificationMessageList}
      IntraTelepharQueueSequence={props.IntraTelepharQueueSequence}
      //ReturnOrder
      drugPermission={props.drugPermission}
      OrderReturnList={props.OrderReturnList}
      DrugReturnList={props.DrugReturnList}
      OrderHistory={props.OrderHistory}
      OrderReturnLog={props.OrderReturnLog}
      // Allergy
      AllergySequence={props.AllergySequence}
    />
  ) : props.divisionType === "แผนกเก็บ Specimen" ? (
    <CardLabOrderQueue
      setProp={props.setProp}
      onEvent={props.onEvent}
      // seq
      runSequence={props.runSequence}
      LabReportSequence={props.LabReportSequence}
      // data
      layout={props.layout}
      labOrderQueue={props.labOrderQueue}
      selectedLabOrderWorking={props.selectedLabOrderWorking}
      permissionLabResultConfidential={props.permissionLabResultConfidential}
      // CommonInterface
      buttonLoadCheck={props.buttonLoadCheck}
      searchedItemListWithKey={props.searchedItemListWithKey}
      successMessage={props.successMessage}
      errorMessage={props.errorMessage}
      selectedPatient={props.selectedPatient}
      // options
      masterOptions={props.masterOptions}
      // config
      size={props.size}
      tableHeight="calc(100dvh - 19rem)"
      style={{ height: "calc(100% - -0.25rem)", overflow: "auto" }}
      // callback
      forward={props?.forward}
    />
  ) : props.divisionType === "การเงิน" ? (
    <CardBilling
      // callback
      setProp={props.setProp}
      onEvent={props.onEvent}
      drugOrderQueueController={props.drugOrderQueueController}
      forward={props?.forward}
      // data
      masterOptions={props.masterOptions}
      successMessage={props.successMessage}
      errorMessage={props.errorMessage}
      billingFilterQueue={props.billingFilterQueue}
      billingQueuePagination={props.billingQueuePagination}
      billingQueueList={props.billingQueueList}
      billingHistorySequence={props.billingHistorySequence}
      runSequence={props.runSequence}
      buttonLoadCheck={props.buttonLoadCheck}
      searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      selectedDevice={props.selectedDevice}
      // ReceiptCode
      bilReceiptCodeDetail={props.bilReceiptCodeDetail}
    />
  ) : props.selectedDivision?.code === "CNADM" ? (
    <CardADM
      // controller
      drugOrderQueueController={props.drugOrderQueueController}
      crdAdmitOrderController={props.crdAdmitOrderController}
      subICD10DetailController={props.subICD10DetailController}
      printListController={props.printListController}
      scannedDocumentController={props.scannedDocumentController}
      // callback
      setProp={props.setProp}
      onEvent={props.onEvent}
      // seq
      runSequence={props.runSequence}
      AdmitChangeSequence={props.AdmitChangeSequence}
      SetBedSequence={props.SetBedSequence}
      // data
      masterOptions={props.masterOptions}
      django={props.django}
      eocIsLoading={props.eocIsLoading}
      successMessage={props.successMessage}
      errorMessage={props.errorMessage}
      searchedItemList={props.searchedItemList}
      buttonLoadCheck={props.buttonLoadCheck}
    />
  ) : props.divisionType === "ห้องเวชภัณฑ์" ? ( // ห้องจ่ายเวชภัณฑ์
    <div id="CardPatientList" style={{ height: "99%", display: "flex", flexDirection: "column" }}>
      <MSDMenuUXMED
        selected={tabMSD}
        queueOrder={() => {
          setTabMSD("queueOrder");
        }}
        deliveryQueue={() => {
          setTabMSD("deliveryQueue");
        }}
        deliveryPrint={() => {
          setTabMSD("refillPrint");
        }}
        supplyOrderHistory={() => {
          setTabMSD("supplyOrderHistory");
        }}
        deliveryHistory={() => {
          setTabMSD("deliveryHistory");
        }}
        stockManagement={() => {
          setTabMSD("stockManagement");
        }}
        returnHistory={() => {
          setTabMSD("returnHistory");
        }}
        queueOrderClicked={tabMSD === "queueOrder"}
        deliveryQueueClicked={tabMSD === "deliveryQueue"}
        deliveryClicked={tabMSD === "deliveryManager"}
        deliveryPrintClicked={tabMSD === "refillPrint"}
        supplyOrderHistoryClicked={tabMSD === "supplyOrderHistory"}
        deliveryHistoryClicked={tabMSD === "deliveryHistory"}
        stockManagementClicked={tabMSD === "stockManagement"}
        returnHistoryClicked={tabMSD === "returnHistory"}
        // hideReturnBtn={CONFIG.RAKSTHAI_HIDE_SUPPLY_SUBMENU} // Feature #63531
        hideDeliveryHistoryBtn={CONFIG.RAKSTHAI_HIDE_SUPPLY_SUBMENU} // Feature #63531
        hideStockBtn={CONFIG.RAKSTHAI_HIDE_SUPPLY_SUBMENU}
        hideSending={CONFIG.RAKSTHAI_HIDE_SUPPLY_SUBMENU}
      />
      <>
        {tabMSD === "queueOrder" ? (
          <>
            <CardSupplyOrderQueueList
              onEvent={props.onEvent}
              setProp={props.setProp}
              forward={props.forward}
              layout={props.layout}
              // seq
              runSequence={props.runSequence}
              DispensingOrderSupplySequence={props.DispensingOrderSupplySequence}
              // controller
              drugOrderQueueController={props.drugOrderQueueController}
              // data
              divisionList={props.divisionList}
              supplyOrderStatus={props.supplyOrderStatus}
              // options
              masterOptions={props.masterOptions}
            />
          </>
        ) : null}
        {tabMSD === "deliveryQueue" ? (
          <>
            <CardSupplyDeliveryQueue
              masterOptions={props.masterOptions}
              divisionList={props.divisionList}
              buttonLoadCheck={props.buttonLoadCheck}
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              setProp={props.setProp}
              onSelected={() => {
                setTabMSD("deliveryManager");
              }}
            />
          </>
        ) : null}
        {tabMSD === "refillPrint" ? (
          <>
            <CardRefillDivisionPrint
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              setProp={props.setProp}
              RefillDivisionSequence={props.RefillDivisionSequence}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          </>
        ) : null}
        {tabMSD === "deliveryManager" ? (
          <>
            <CardSupplyDeliveryManager
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          </>
        ) : null}
        {tabMSD === "supplyOrderHistory" ? (
          <>
            <CardSupplyOrderHistory
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              masterOptions={props.masterOptions}
              SupplyOrderHistorySequence={props.SupplyOrderHistorySequence}
              controller={props.drugOrderHistoryController}
              DispensingOrderSupplySequence={props.DispensingOrderSupplySequence}
              supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
            />
          </>
        ) : null}
        {tabMSD === "deliveryHistory" ? (
          <>
            <CardSupplyDeliveryHistory
              onEvent={props.onEvent}
              runSequence={props.runSequence}
              setProp={props.setProp}
              buttonLoadCheck={props.buttonLoadCheck}
              SupplyDeliveryHistorySequence={props.SupplyDeliveryHistorySequence}
              masterOptions={props.masterOptions}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              onSelected={() => {
                setTabMSD("deliveryManager");
              }}
            />
          </>
        ) : tabMSD === "returnHistory" ? (
          <>
            <CardMedRequestReturn
              setProp={props.setProp}
              onEvent={props.onEvent}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              selectedEncounter={props.selectedEncounter}
              buttonLoadCheck={props.buttonLoadCheck}
              MedRequestReturnSequence={props.MedRequestReturnSequence}
              ReturnSupplySequence={props.ReturnSupplySequence}
              loadingStatus={props.loadingStatus}
            />
          </>
        ) : tabMSD === "stockManagement" ? (
          <div style={{ height: "calc(100dvh - 100px)", paddingTop: "5px" }}>
            <Tab
              tabWidth="150px"
              // selectedTabIndex={0}
              data={[
                {
                  key: "การจัดการสินค้า",
                  name: "การจัดการสินค้า",
                  render: () => (
                    <CardStockManagement
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      StockManagementSequence={props.StockManagementSequence}
                      // data
                      selectedDivision={props.selectedDivision}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      errorMessage={props.errorMessage}
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      // options
                      masterOptions={props.masterOptions}
                    />
                  ),
                },
                {
                  key: "รายการโอนสินค้า (Transfer request)",
                  name: (
                    <div
                      onClick={() => {
                        props.setProp("transferOrderCount", 0);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label>รายการโอนสินค้า (Transfer request)</label>
                      {props.transferOrderCount > 0 && (
                        <Label
                          data-testid="msg-noti"
                          circular
                          color={"red"}
                          size="mini"
                          onClick={() => {
                            props.setProp("transferOrderCount", 0);
                          }}
                        >
                          {props.transferOrderCount}
                        </Label>
                      )}
                    </div>
                  ),
                  render: () => (
                    <CardStockTransferOrder
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      StockTransferOrderSequence={props.StockTransferOrderSequence}
                      masterOptions={props.masterOptions}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      errorMessage={props.errorMessage}
                      selectedDivision={props.selectedDivision}
                    />
                  ),
                }
              ]}
            />
          </div>
        ) : null}
      </>
    </div>
  ) : (
    <div>
      แผนกทั่วไป {props.selectedDivision?.code} {" tab: "} {props.tab}{" "}
    </div>
  );
});

export const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : undefined}
    style={{ paddingRight: "1.15rem", paddingLeft: "1.15rem" }}
    onClick={() => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

CardPatientList.displayName = "CardPatientList";
export default CardPatientList;
