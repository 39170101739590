import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Icon, Input } from "semantic-ui-react";

// UX
import CardVerbalOrderUX from "./CardVerbalOrderUX";
import CardDrugVerbalOrder from "./CardDrugVerbalOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardVerbalOrderHistory from "./CardVerbalOrderHistory";
import CardAdmitOrder from "react-lib/apps/ADM/CardAdmitOrder";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardDoctorNoteOrder from "react-lib/apps/HISV3/DPO/CardDoctorNoteOrder";
import CardImagingOrder from "react-lib/apps/HISV3/IME/CardImagingOrder";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown, { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";

// Const
const DOCTOR_SEARCH_ID = "Doctor_VO";

const CardVerbalOrder = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [containerName, setContainerName] = useState<string>("drug");
  const [selectedDoctor, setSelectedDoctor] = useState<Record<string, any> | null>(null);
  const [password, setPassword] = useState<string>("");
  const [orderType, setOrderType] = useState("");
  const [drugType, setDrugType] = useState("");
  const [orderDiv, setOrderDiv] = useState("");
  const [note, setNote] = useState("");
  const [editOrder, setEditOrder] = useState<any>(null);
  const [selectedImagingId, setSelectedImagingId] = useState<number | null>(null);

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [verbalOrderSave, setVerbalOrderSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  // Effect

  // * Clear verbalOrderHistoryList แท็บ ประวัติ รคส. เมื่อ เข้าหน้า รคส. แพทย์ ครั้งแรก
  useEffect(() => {
    props.setProp("verbalOrderHistoryList", []);
  }, []);

  useEffect(() => {
    console.log("duplicateLabDetected ", props.duplicateLabDetected);
    if (props.duplicateLabDetected === true) {
      setOpenConfirm(true);
    }
  }, [props.duplicateLabDetected]);

  useEffect(() => {
    const roleTypes = props.django?.user?.role_types || [];

    if (
      ["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType) &&
      (roleTypes || []).includes("REGISTERED_NURSE") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      const callFrom = "WARD";

      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด Card Nurse Note: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  useEffect(() => {
    if (
      props.errorMessage?.CardVerbalOrder !== null &&
      props.errorMessage?.CardVerbalOrder !== undefined
    ) {
      setLoading(false);
      console.log('setLoading(false)');
      setErrorMessage(props.errorMessage?.CardVerbalOrder);

      // setTimeout(() => {
      //   props.onEvent({
      //     message: "SaveVerbalOrder",
      //     params: { action: "clearMessage" },
      //   });
      // }, 5000);
    }
    if (
      props.successMessage?.CardVerbalOrder !== null &&
      props.successMessage?.CardVerbalOrder !== undefined
    ) {
      setLoading(false);
      console.log('setLoading(false)');
      setCompleted(true);

      handleClearValue();

      setTimeout(() => {
        setCompleted(false);
        // #props.setProp("successMessage.CardVerbalOrder", "");
        // props.onEvent({
        //   message: "SaveVerbalOrder",
        //   params: { action: "clearMessage" },
        // });
      }, 3000);
      // #toast.success("บันทึกข้อมูลสำเร็จ");
    }
  }, [props.successMessage?.CardVerbalOrder, props.errorMessage?.CardVerbalOrder]);

  useEffect(() => {
    setOrderDiv(props.selectedDivision?.id);
  }, [props.selectedDivision]);

  // callback
  const handleSelectedItem: SelectedItemHandler = useCallback(
    async (value, key, data) => {
      setSelectedDoctor(data || null);
    },
    [props.searchedItemListWithKey]
  );

  // useEffect(() => {
  //   console.log('props.verbalOrderLogin?.name: ', props.verbalOrderLogin?.name);
  //   if (props.verbalOrderLogin?.name) {
  //     setPassword(props.verbalOrderLogin?.name)
  //   }
  // }, [props.verbalOrderLogin?.pass, props.verbalOrderLogin?.name])


  // Memo
  const drugTypeItems = useMemo(() => {
    if (props.selectedEncounter?.type === "IPD") {
      return [
        {
          key: "DRUG_ORDER_HOME_IPD",
          value: "DRUG_ORDER_HOME_IPD",
          text: "IPD HOME รายการสั่งยากลับบ้านผู้ป่วยใน",
        },
        {
          key: "DRUG_ORDER_ONE_DAY",
          value: "DRUG_ORDER_ONE_DAY",
          text: "ONE DAY รายการสั่งยาใช้ภายในวันผู้ป่วยใน",
        },
        {
          key: "DRUG_ORDER_ONE_DOSE__IPD",
          value: "DRUG_ORDER_ONE_DOSE__IPD",
          text: "ONE DOSE รายการสั่งยาใช้ในโรงพยาบาลผู้ป่วยใน",
        },
      ];
    } else {
      return [
        {
          key: "DRUG_ORDER_HOME_OPD",
          value: "DRUG_ORDER_HOME_OPD",
          text: "OPD HOME รายการสั่งยากลับบ้านผู้ป่วยนอก",
        },
        {
          key: "DRUG_ORDER_ONE_DOSE__OPD",
          value: "DRUG_ORDER_ONE_DOSE__OPD",
          text: "ONE DOSE รายการสั่งยาใช้ในโรงพยาบาลผู้ป่วยนอก",
        },
      ];
    }
  }, [props.selectedEncounter?.type]);

  const orderByName = useMemo(() => {
    const nameCode: string = selectedDoctor?.name_code || "";

    return nameCode.replace(/ ?\(.*\)/, "") || "-";
  }, [selectedDoctor]);

  // Handler
  const handleClearValue = async () => {
    setOrderType("");
    setSelectedDoctor(null);
    setPassword("");

    props.setProp(`searchedItemListWithKey.${DOCTOR_SEARCH_ID}`, []);

    // Drug
    setOrderDiv(props.selectedDivision?.id);
    setNote("");
    setDrugType("");
    setEditOrder(null);

    const tab = containerName;

    await setContainerName("");

    setContainerName(tab);
  };

  const handleSave = () => {
    setLoading(true);
    console.log('setLoading(true)');

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: drugType,
        order_type: orderType,
        order: {
          ...editOrder,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "REQUEST",
          emr: props.selectedEmr?.id,
          order_div: orderDiv.toString(),
          is_out_perform_div: false,
        },
      },
    });
  };

  const handleSaveLab = (allowDupLab: boolean) => {
    setLoading(true);
    console.log('setLoading(true)');
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "LAB_ORDER",
        order_type: orderType,
        order: {
          ...editOrder,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          progression_cycle: props.selectedProgressCycle?.id,
          emr: props.selectedEmr?.id,
          encounter: props.selectedEncounter?.id,
          order_items: props.OrderCentralLabSequence?.labOrder?.order_items,
          note: props.OrderCentralLabSequence.labOrder?.note,
          allow_duplicate_flag: allowDupLab || false,
          order_status: props.OrderCentralLabSequence?.labOrder?.order_status
            ? props.OrderCentralLabSequence.labOrder.order_status
            : props.selectedEncounter?.type === "IPD"
            ? 2
            : 2,
        },
      },
    });
  };

  const handleSaveTreatment = () => {
    setLoading(true);
    console.log('setLoading(true)');

    const orderItems = editOrder?.ref_order
      ? props?.OrderTreatmentSequence?.treatmentList
      : props.OrderTreatmentSequence?.orderItems;
    let orderItemsNoId = [];

    if (orderItems) {
      if (editOrder?.ref_order) {
        orderItemsNoId = orderItems.map(function (item: any) {
          delete item.order;
          delete item.pk;
          return item;
        });
      } else {
        orderItemsNoId = orderItems.map(function (item: any) {
          delete item.id;
          return item;
        });
      }
    }

    // copy from OrderTreatment.ts > SearchAndAdd > action save
    const principal = props.OrderTreatmentSequence?.diagnosisListPrincipal || [];
    const secondary = props.OrderTreatmentSequence?.diagnosisListSecondary || [];
    const coreProcedure = [...principal, ...secondary];
    const procedure = coreProcedure?.map((item: any) => ({
      active: item.active,
      created: item.created,
      edit_user: item.edited,
      emr: item.emr,
      icd9cm: item.icd9cm,
      icd9cm_code: item.icd9cm_code,
      icd9cm_med_term: item.medterm_id || null,
      icd9cm_term: item.icd9cm_term,
      id: item.id,
      medical_description: item.medterm_id ? item.icd9cm_med_term : item.medical_description,
      order: item.order,
      type: item.type,
    }));
    const core_procedure = editOrder ? procedure : coreProcedure;
    // ----- end copy

    let data = {
      ...editOrder,
      id: editOrder?.ref_order,
      action: editOrder ? "EDIT" : "ORDER",
      claim_payload: null,
      core_procedure: core_procedure,
      emr: props.selectedEmr?.id,
      encounter: props.selectedEncounter?.id,
      equipment_detail: props.treatmentOrderList?.equipmentDetail,
      is_advance: false,
      is_operation_division: false,
      is_out_perform_div: false,
      note: "",
      order_detail: props.treatmentOrderList?.orderDetail,
      order_items: orderItemsNoId,
      order_status: 2,
      order_summary_detail: "",
      order_time: null,
      out_perform_div: "",
      progression_cycle: "",
      show_alert: true,
      to_be_performer: props.treatmentOrderList?.toBePerformer,
      _items: props?.OrderTreatmentSequence?.treatmentList,
      _principalDiag: [],
      _secondaryDiag: [],
    };

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "TREATMENT_ORDER",
        order_type: orderType,
        order: data,
      },
    });
  };

  const handleSaveAdmitOrder = (data: {}) => {
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "ADMIT_ORDER",
        order_type: orderType,
        order: {
          ...editOrder,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          ...data,
        },
      },
    });

    setVerbalOrderSave(false);
  };

  const handleSaveDupLab = () => {
    props.setProp("duplicateLabDetected", false);
    handleSaveLab(true);
    handleCloseModConfirm();
  };

  const handleSaveImagingOrder = () => {
    setLoading(true);

    const imaging = props.ImagingOrderSequence;
    const list = imaging?.imagingItemList || [];
    const filterList = list.filter((item: any) => !!item.active);
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: props.DoctorNoteOrderSequence?.note || "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "IMAGING_ORDER",
        order_type: orderType,
        order: {
          ...props.ImagingOrderSequence?.imagingItemDetail,
          order_items: filterList,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "ORDER",
          encounter: props.selectedEncounter?.id,
          emr: props.selectedEmr?.id,
          order_div: orderDiv.toString(),
          is_out_perform_div: false,
        },
      },
    });
  };

  const handleSaveDoctorNote =() =>{
    setLoading(true);

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        id: editOrder?.id,
        encounterId: props.selectedEncounter?.id,
        note: props.DoctorNoteOrderSequence?.note || "",
        order_by: selectedDoctor?.id || null,
        repassword: password || "",
        order_specific_type: "DOCTOR_NOTE_ORDER",
        order_type: orderType,
        order: {
          ...props.DoctorNoteOrderSequence,
          id: editOrder?.ref_order,
          action: editOrder ? "EDIT" : "REQUEST",
          emr: props.selectedEmr?.id,
          order_div: orderDiv.toString(),
          is_out_perform_div: false,
        },
      },
    });
  };

  const handleCloseModConfirm = () => {
    props.setProp("duplicateLabDetected", false);

    setOpenConfirm(false);
    setLoading(false)
    console.log('setLoading(false)');
  };

  const handleEditVerbalOrder = (item: any) => {
    // do something here for edit order
    const tabs = {
      LAB_ORDER: "lab",
      DRUG_ORDER_HOME_OPD: "drug",
      DRUG_ORDER_ONE_DOSE__OPD: "drug",
      TREATMENT_ORDER: "treatment",
      ADMIT_ORDER: "admit",
      DRUG_ORDER_HOME_IPD: "drug",
      DRUG_ORDER_ONE_DOSE__IPD: "drug",
      DRUG_ORDER_ONE_DAY: "drug",
      IMAGING_ORDER: "imaging",
      DOCTOR_NOTE_ORDER: "drnote",
    } as any;

    const tab = tabs[item.order_specific_type];
    const division = props.masterOptions?.division?.find(
      (option: any) => option.text === item.division
    )?.value;

    if (tab) {
      const doctor = { id: item.co_signed_id, name_code: item.co_signed };
      // * Set doctor, order type

      props.setProp("searchedItemListWithKey.Doctor_VO", [doctor]);

      setOrderType(item.order_type);
      setSelectedDoctor(doctor);

      if (tab === "drug") {
        setDrugType(item.order_specific_type);
        setNote(item.note);
        setOrderDiv(division);
      } else if (tab === "treatment") {
        props.setProp("treatmentOrderEditId", item.ref_order);
      } else if (tab === "imaging") {
        setSelectedImagingId(item.ref_order);
      } else if (tab === "drnote") {
        props.setProp("doctorNoteOrderEditId", item.ref_order);
      }
      setContainerName(tab);
      setEditOrder(item);
    }
  };

  const handleApproveMedReconcile = () => {
    props.setProp("medReconcileCheck.open_card_med_reconciliation", false);

    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selecteRecordViewIndex: props.medReconcileIndex,
      },
    });
  };

  const handleSaveVerbalOrder = () => {
    if (containerName === "lab") {
      handleSaveLab(false);
    } else if (containerName === "treatment") {
      handleSaveTreatment();
    } else if (containerName === "admit") {
      setLoading(true);
      console.log('setLoading(true)');
      setVerbalOrderSave(true);
    } else if (containerName === "imaging") {
      handleSaveImagingOrder();
    } else if (containerName === "drnote") {
      handleSaveDoctorNote();
    } else {
      handleSave();
    }
  };

  const handleChangeTab = (tab: any) => {
    setContainerName(tab);
    setEditOrder(null);
    setOrderType("");
    setSelectedDoctor(null);

    // Clear ยา
    setDrugType("");
    setNote("");
    setOrderDiv(props.selectedDivision?.id || "");

    // Clear password
    setPassword("")
    // props.onEvent({
    //   message: "CheckPasswordVerbalOrder",
    //   params: {
    //     action: "Clear",
    //   },
    // });
  };

  return (
    <div id="CardVerbalOrder" style={{ padding: "0 10px", paddingBottom: "2rem" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          setErrorMessage(null)
          props.onEvent({
            message: "SaveVerbalOrder",
            params: { action: "clearMessage" },
          });
        }}
        error={errorMessage}
        success={null}
      />

      <CardVerbalOrderUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        // open tab
        containerName={containerName}
        method={orderType}
        saveloading={loading}
        disabledSave={loading}
        hiddenPerformed={containerName === "history"}
        // options
        methodOptions={[
          { key: "VERBAL", value: "VERBAL", text: "ทางวาจา" },
          { key: "TELEPHONE", value: "TELEPHONE", text: "ทางโทรศัพท์" },
        ]}
        hideAdmitOrder={props.selectedEncounter?.type === "IPD"}
        // callback
        onChangeTab={handleChangeTab}
        methodOnChange={(_e: any, { value }: any) => {
          setOrderType(value);
        }}
        saveVerbalOrder={handleSaveVerbalOrder}
        // Element
        save={completed ? <Icon className="check"></Icon> : "SAVE"}
        doctorSearchBox={
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type={"Doctor"}
              id="VO"
              style={{ width: "100%" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              upward={true}
              inline
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctor?.id || null}
              setSelectedItem={handleSelectedItem}
            />
        }
        rePasswordKey={<div>ยืนยันรหัสผู้รับคำสั่ง<span style={{color:"red"}}>*</span></div>}
        rePassword={
          <PasswordRecheck
            id="CardVerbalOrder-input-3"
            password={password}
            fluid
            setPassword={setPassword}
          />
         }
          // <EmployeeToken
          //   placeholder={"จำเป็นต้องระบุรหัสผ่าน"}
          //   onEnterToken={(code) => {
          //     props.onEvent({
          //       message: "UserTokenize",
          //       params: {
          //         action: "CheckPasswordKeying",
          //         code: code,
          //       },
          //     });

          //     // props.onEvent({
          //     //   message: "HandleGetDeliverDrugTokenization",
          //     //   params: { code },
          //     // });
          //   }}
          //   onClearToken={() => {
          //     props.setProp("userTokenize", {
          //       ...props.userTokenize,
          //       token: "",
          //       employeeName: "",
          //       loading: false,
          //       error: null,
          //     });
          //   }}
          //   error={!!props.userTokenize?.error}
          //   loading={props.userTokenize?.loading}
          //   employeeName={props.userTokenize?.employeeName}
          // />

        container={
          <div style={{ width: "100%" }}>
            {containerName === "lab" ? (
              <div style={{ width: "100%", marginTop: "-10px" }}>
                <CardLabRequest
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  // seq
                  runSequence={props.runSequence}
                  OrderCentralLabSequence={props.OrderCentralLabSequence}
                  // data
                  doctorLabOrderList={props.doctorLabOrderList}
                  editId={editOrder?.ref_order}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  selectedEmr={props.selectedEmr}
                  selectedProgressCycle={props.selectedProgressCycle}
                  // style
                  saveLabOrderStyle={{ display: "none" }}
                  doctorLabOrderTableStyle={{ display: "none" }}
                  // config
                  isRestart={true}
                  hideActionButton={true}
                  hidePreOrder={true}
                />
              </div>
            ) : containerName === "drug" ? (
              <div style={{}}>
                <CardDrugVerbalOrder
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  drugType={drugType}
                  note={note}
                  orderDiv={orderDiv}
                  disabledDrugType={!!editOrder}
                  drugTypeOptions={drugTypeItems || []}
                  orderDivOptions={props.masterOptions?.division || []}
                  drugTypeChange={(_e: any, { value }: any) => {
                    setDrugType(value);
                  }}
                  orderDivChange={(_e: any, { value }: any) => {
                    setOrderDiv(value);
                  }}
                  noteChange={(e: any) => {
                    setNote(e.target.value);
                  }}
                />
              </div>
            ) : containerName === "admit" ? (
              <div id="CardAdmitOrder" style={{ width: "100%" }}>
                <CardAdmitOrder
                  controller={props.crdAdmitOrderController}
                  orderId={editOrder?.ref_order}
                  subICDController={props.subICDController}
                  DJANGO={props.django}
                  PATIENT_DATA={props.PATIENT_DATA}
                  showHeader={false}
                  allowClear={true}
                  hideSaveButton={false}
                  hideCancelButton={false}
                  closeable={false}
                  toggleable={false}
                  hideBottom={true}
                  labelFitContent={true}
                  verbalOrderSave={verbalOrderSave}
                  orderByName={orderByName}
                  onSaveVerbalOrder={handleSaveAdmitOrder}
                />
              </div>
            ) : containerName === "treatment" ? (
              <div style={{ width: "100%" }}>
                <CardTreatmentOrder
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  isVerbalOrder={true}
                  claimOptions={props.masterOptions.claimTreatment}
                  OrderTreatmentSequence={props.OrderTreatmentSequence}
                  searchedItemList={props.searchedItemList}
                  treatmentOrderList={props.treatmentOrderList}
                  successMessage={props.successMessage}
                  errorMessage={props.errorMessage}
                  hideSaveButton={true}
                  procedureItems={props.procedureItems}
                  secondaryItems={props.secondaryItems}
                  treatmentTemplateList={props.treatmentTemplateList}
                  selectedProgressCycle={props.selectedProgressCycle}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  selectedEncounter={props.selectedEncounter}
                  setEditItem={handleEditVerbalOrder}
                  // config
                  hidePreOrder={true}
                />
              </div>
            ) : containerName === "imaging" ? (
              <div style={{ width: "100%" }}>
                <CardImagingOrder
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // seq
                  runSequence={props.runSequence}
                  ImagingOrderSequence={props.ImagingOrderSequence}
                  // data
                  orderId={selectedImagingId || null}
                  // CommonInterface
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // selectedEncounter={props.selectedEncounter}
                  userTokenize={props.userTokenize}
                  errorMessage={props.errorMessage}
                  modXrayDetail={props.modXrayDetail}
                  // options
                  masterOptions={props.masterOptions}
                  // config
                  editMode={true}
                  hideButtonSave={true}
                  // ----- Dental Diagram
                  clinicalFindingId={props.clinicalFindingId}
                  clinicalFindingIndex={props.clinicalFindingIndex}
                  clinicalFindings={props.clinicalFindingList || []}
                  organ={props.organ}
                  clinicaltags={props.clinicaltags}
                  filterClinicalFindingIds={props.filterClinicalFindingIds || []}
                  // -----#
                />
              </div>
            ) : containerName === "drnote" ? (
              <div style={{ width: "100%" }}>
                <CardDoctorNoteOrder
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // permission
                  drNoteOrderPermission={props.drNoteOrderPermission}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // seq
                  runSequence={props.runSequence}
                  DoctorNoteOrderSequence={props.DoctorNoteOrderSequence}
                  // options
                  drNoteDivisionOptions={props.drNoteDivisionOptions}
                  // data
                  DJANGO={props.django}
                  hideButtonSave={true}
                  // modPlanning
                  modPlanningConfig={props.modPlanningConfig}
                  planningData={props.planningData}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  selectedEncounter={props.selectedEncounter}
                />
              </div>
            ) : containerName === "history" ? (
              <div style={{ width: "100%" }}>
                <CardVerbalOrderHistory
                  actionable={true}
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  searchedItemList={props.searchedItemList}
                  loadingStatus={props.loadingStatus}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  setEditItem={handleEditVerbalOrder}
                  verbalOrderHistoryFilter={props.verbalOrderHistoryFilter}
                  verbalOrderHistoryList={props.verbalOrderHistoryList}
                  promptMessage={props.promptMessage}
                  duplicateLabDetected={props.duplicateLabDetected}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        }
      />

      <ModMedReconcileAlert
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={handleApproveMedReconcile}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยพยาบาล`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <ModConfirm
        openModal={openConfirm}
        titleName="กรุณายืนยัน"
        // @ts-ignore
        content={
          <>
            {props.promptMessage?.length === 2 ? (
              <>
                {props.promptMessage?.[0]} <br />
                <div dangerouslySetInnerHTML={{ __html: props.promptMessage?.[1] }} />
              </>
            ) : (
              props.promptMessage
            )}
          </>
        }
        onApprove={handleSaveDupLab}
        onDeny={handleCloseModConfirm}
      />
    </div>
  );
};

export default React.memo(CardVerbalOrder);
