import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  MutableRefObject,
  CSSProperties,
} from "react";
import { Button } from "semantic-ui-react";

import moment from "moment";

// UI
import CardSurgeryTeam from "./CardSurgeryTeam";
import CardAnesthesiaTeam, { CardAnesthesiaTeamRef } from "./CardAnesthesiaTeam";
import CardORDetail from "./CardORDetail";
import CardORRequestUX from "./CardORRequestUX";
import ORRequestMessage from "./ORRequestMessage";

// Common
import { DateTextBox } from "react-lib/apps/common";
import SnackMessage from "../../common/SnackMessage";
import ButtonLoadCheck from "../../../appcon/common/ButtonLoadCheck";
import ModConfirmAuthen from "../../common/ModConfirmAuthen";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

// Interface
import {
  BloodRequestType,
  ChangeNameType,
  PickedProps,
  SetProp,
  State,
} from "./sequence/ORRequest";

// Types
type CardORRequestProps = {
  setProp: SetProp;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  ORRequestSequence: State["ORRequestSequence"];
  // controller
  proxyController: any;
  // data
  id?: string;
  encounterId: number;
  operatingOrderId: number;
  isNewEncounter: boolean;
  patientId: number;
  emrId?: number;
  patientAppointmentId?: number;
  divisionId?: number;
  // CommonInterface
  masterData?: any;
  // config
  isCancelConfirm?: boolean;
  isConfirm?: boolean;
  phvReadOnly?: boolean;
  isCancel?: boolean;
  // style
  cardStyle?: CSSProperties;
  // callback
  onCreateSuccess?: () => any;
  onSaveSuccess?: () => any;
  setTab?: (tab: string) => any;
  setModOrder?: (order: string) => any;
  divisionType?: any;
  django?: any;
} & PickedProps;

// Const
const ADMIT_CASE_OPTIONS = ["Admit ก่อนผ่าตัด", "Admit หลังผ่าตัด"].map((value) => ({
  key: value,
  value,
  text: value,
}));

const OR_TYPE = {
  ELECTIVE_CASE: "EL",
  EMERGENCY_CASE: "ER",
  NON_SCHEDULE: "N",
  ELECTIVE_NOT_APP_CASE: "EL_NOT_APP",
};

const OPERATING_ORDER_ACTION = {
  CONFIRM: "CONFIRM",
  CANCEL_CONFIRM: "CANCEL_CONFIRM",
  EDIT_REQUEST: "EDIT_REQUEST",
  CANCEL: "CANCEL",
  REQUEST: "REQUEST",
  PRINT: "PRINT",
};

const CARD_OR_REQUEST = "CardOrRequest";

const CardORRequest = (props: CardORRequestProps) => {
  const [openModBloodBank, setOpenModBloodBank] = useState<boolean>(false);

  const orRef = useRef() as MutableRefObject<HTMLDivElement>;
  const anesthesiaTeamRef = useRef<CardAnesthesiaTeamRef>(null);
  const shouldScrollRef = useRef(false);

  const editable = useMemo(() => {
    const isUserDoctorOrNurse = props.django?.user?.role_types?.includes(
      "DOCTOR",
      "REGISTERED_NURSE"
    );

    const isOperatingRoom = props.divisionType === "ห้องผ่าตัด";

    const isRestrictedEdit =
      props.django?.user && props.divisionType && isUserDoctorOrNurse && isOperatingRoom;

    const hasEditRequestPermission = props.ORRequestSequence?.allowed_action?.includes(
      OPERATING_ORDER_ACTION.EDIT_REQUEST
    );

    // เงื่อนไขสุดท้าย
    const isEdit = isRestrictedEdit ? false : hasEditRequestPermission;

    return !!isEdit;
  }, [props.ORRequestSequence?.allowed_action, props.django?.user, props.divisionType]);

  useEffect(() => {
    if (orRef.current && props.ORRequestSequence?.allowed_action) {
      PreventClickEvent(editable, orRef.current);
    }
  }, [editable]);

  useEffect(() => {
    console.log("OR Request");
    const fetchData = async () => {
      await props.setProp("ORRequestSequence", {});

      props.runSequence({
        sequence: "ORRequest",
        restart: true,
        encounterId: props.encounterId,
        operatingOrderId: props.operatingOrderId,
        card: CARD_OR_REQUEST,
        isNewEncounter: props.isNewEncounter,
        patientId: props.patientId,
        emrId: props.emrId,
      });
    };

    fetchData();
  }, [props.operatingOrderId, props.encounterId]);

  useEffect(() => {
    const length = props.ORRequestSequence?.anesthesia_teams?.length;

    if (length && shouldScrollRef.current) {
      anesthesiaTeamRef.current?.containerRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

      shouldScrollRef.current = false;
    }
  }, [props.ORRequestSequence?.anesthesia_teams]);

  const getAllowedAction = useCallback(
    (action: string) => {
      const permission = props.ORRequestSequence?.permission || {};
      const allowedAction = props.ORRequestSequence?.allowed_action || [];

      const permissionAction = permission?.[`action_ORM_OperatingOrder_${action}`];

      if (typeof permissionAction === "undefined") {
        return false;
      }

      if (!permissionAction) {
        return false;
      } else if (allowedAction.includes((OPERATING_ORDER_ACTION as any)[action])) {
        return true;
      }
      return false;
    },
    [props.ORRequestSequence?.permission, props.ORRequestSequence?.allowed_action]
  );

  const isEncounterOPD = useMemo(() => {
    return (
      props.ORRequestSequence?.type !== OR_TYPE.ELECTIVE_CASE &&
      props.ORRequestSequence?.encounterType === "OPD"
    );
  }, [props.ORRequestSequence?.type]);

  const orTypeOptions = useMemo(() => {
    // #const array = props.isNewEncounter
    //   ? [OR_TYPE.ELECTIVE_CASE]
    //   : [OR_TYPE.EMERGENCY_CASE, OR_TYPE.ELECTIVE_NOT_APP_CASE];
    const array = [OR_TYPE.ELECTIVE_CASE, OR_TYPE.EMERGENCY_CASE, OR_TYPE.ELECTIVE_NOT_APP_CASE];

    if (isEncounterOPD) {
      array.shift();
    }

    const items = props.masterOptions.orType || [];

    return items.filter((item: any) => array.includes(item.value));
  }, [props.masterOptions.orType, isEncounterOPD]);

  const showCancelConfirm = useMemo(() => {
    return getAllowedAction(OPERATING_ORDER_ACTION.CANCEL_CONFIRM) && props.isCancelConfirm;
  }, [
    props.ORRequestSequence?.permission,
    props.ORRequestSequence?.allowed_action,
    props.isCancelConfirm,
    props.isConfirm,
  ]);

  const handleChangeValue = async (e: any, v: any) => {
    if (!editable) {
      return;
    }

    let value = typeof v.checked === "boolean" ? v.checked : v.value;
    let name = v.name as ChangeNameType;
    let number: number | undefined | string;

    if (value === "") {
      number = "";
    } else {
      number = +value < 0 ? "0" : value;
    }

    value = v.type === "number" ? number : value;

    if (name === "blood_request") {
      const bloodKey = value as keyof BloodRequestType;

      name = `blood_request.${bloodKey}`;
      value = true;
    }

    const split = name?.split(".") || [];
    const lastKey = split.slice(-1)?.[0] || "";

    props.runSequence({
      sequence: "ORRequest",
      action: "edit",
      value,
      name,
      callback: async () => {
        await props.setProp(`ORRequestSequence.${name}`, value);

        if (lastKey === "is_main") {
          props.runSequence({
            sequence: "ORRequest",
            action: "update_location_room",
          });
        }
      },
    });
  };

  const handleChangeDate = (name: string) => (date: string) => {
    handleChangeValue(null, { name, value: date });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "ORRequest",
      action: "clear_screen",
      card: CARD_OR_REQUEST,
    });
  };

  const handleSave = (action: string) => (e: any, v: any) => {
    props.runSequence({
      sequence: "ORRequest",
      action,
      patientAppointmentId: props.patientAppointmentId,
      encounterId: props.encounterId,
      operatingOrderId: props.operatingOrderId,
      card: CARD_OR_REQUEST,
      card_action: `${CARD_OR_REQUEST}${v.name}`,
      is_appointment: props.patientAppointmentId ? true : props.ORRequestSequence?.is_appointment, //props.ORRequestSequence?.type === OR_TYPE.ELECTIVE_CASE,
      isNewEncounter: props.isNewEncounter,
      onCreated: props.onCreateSuccess,
      patientId: props.patientId,
      onSaveSuccess: props.onSaveSuccess,
    });
  };

  const handleConfirmOrderBloodBank = () => {
    const action = props.ORRequestSequence?.id
      ? OPERATING_ORDER_ACTION.EDIT_REQUEST
      : OPERATING_ORDER_ACTION.REQUEST;

    props.runSequence({
      sequence: "ORRequest",
      action,
      patientAppointmentId: props.patientAppointmentId,
      encounterId: props.encounterId,
      operatingOrderId: props.operatingOrderId,
      card: CARD_OR_REQUEST,
      card_action: "REQUEST_BLOOD_BANK",
      is_appointment: props.patientAppointmentId ? true : props.ORRequestSequence?.is_appointment, //props.ORRequestSequence?.type === OR_TYPE.ELECTIVE_CASE,
      isNewEncounter: props.isNewEncounter,
      onCreated: () => {
        setOpenModBloodBank(false);
        props.setTab?.("otherorderappointment");
        props.setModOrder?.("BLOOD_BANK");
      },
      onError: () => {
        setOpenModBloodBank(false);
      },
    });
  };

  const handleCloseModConfirm = () => {
    props.setProp("ORRequestSequence.modConfirm", 0);
    props.setProp("userTokenize", null);
  };

  const handleConfirm = (input: string) => {
    props.runSequence({
      ...props.ORRequestSequence?.modConfirm,
      encounterId: props.encounterId,
      operatingOrderId: props.operatingOrderId,
      isNewEncounter: props.isNewEncounter,
      onSaveSuccess: props?.onSaveSuccess || null,
      patientId: props?.patientId,
      cancel_reason: input,
    });
  };

  const handleAddAnsTeam = () => {
    shouldScrollRef.current = true;
  };

  // ไม่ให้ tab index ใช้งานได้
  useEffect(() => {
    if (orRef.current && props.phvReadOnly) {
      const children = Array.from(
        orRef.current.querySelectorAll(".dropdown, .input, .search, input")
      ) as HTMLElement[];

      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
      });
    }
  }, [orRef?.current, props.phvReadOnly]);

  return (
    <div
      id="CardORRequest"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "82vh",
        position: "relative",
        paddingBottom: "1.5rem",
        ...(props.cardStyle || {}),
      }}
    >
      {/* <Dimmer active={props.loadingStatus?.[CARD_OR_REQUEST] || false} inverted>
        <Loader />
      </Dimmer> */}

      <div ref={orRef}>
        <CardORRequestUX
          // data
          checkin_code={props.ORRequestSequence?.checkin_code}
          type={props.ORRequestSequence?.type}
          clinicType={props.ORRequestSequence?.clinic_type}
          isOpdCase={props.ORRequestSequence?.case?.is_opd_case}
          isOneDayCase={props.ORRequestSequence?.case?.is_one_day_case}
          isIpdCase={props.ORRequestSequence?.case?.is_ipd_case}
          ipdCase={props.ORRequestSequence?.case?.ipd_case}
          ipdDay={props.ORRequestSequence?.case?.ipd_day}
          isRequestIcu={props.ORRequestSequence?.case?.is_request_icu}
          isAppAdmit={props.ORRequestSequence?.case?.is_app_admit}
          icuDay={props.ORRequestSequence?.case?.icu_day}
          isMedBlock={props.ORRequestSequence?.is_med_block}
          isAnesthesia={props.ORRequestSequence?.is_anesthesia}
          isOther={props.ORRequestSequence?.is_other}
          other={props.ORRequestSequence?.other}
          admitOrder={props.ORRequestSequence?.admit_order}
          // options
          orTypeOptions={orTypeOptions}
          orClinicTypeOptions={props.masterOptions.orClinicType}
          admitCaseOptions={ADMIT_CASE_OPTIONS}
          admitOrderOptions={props.ORRequestSequence?.admitOrderOptions}
          // config
          isEncounterOPD={isEncounterOPD}
          config={{
            disabledIpdCase: !props.ORRequestSequence?.case?.is_ipd_case,
            disabledRequestIcu: !props.ORRequestSequence?.case?.is_request_icu,
            disabledOther: !props.ORRequestSequence?.is_other,
            allowedConfirm: getAllowedAction(OPERATING_ORDER_ACTION.CONFIRM) && props.isConfirm,
            allowedCancelConfirm: showCancelConfirm,
            allowedCancel: getAllowedAction(OPERATING_ORDER_ACTION.CANCEL) && props.isCancel,
            allowedPrint: props.ORRequestSequence?.id,
          }}
          // callback
          onChangeValue={handleChangeValue}
          onClear={handleClear}
          // Element
          DateTextBoxApp={
            <DateTextBox
              value={props.ORRequestSequence?.case?.app_admit_date || ""}
              minDate={formatDate(props.ORRequestSequence?.extra?.minDate || "")}
              maxDate={formatDate(props.ORRequestSequence?.extra?.maxDate || "")}
              inputFluid={true}
              disabled={!editable || isEncounterOPD}
              onChange={handleChangeDate("case.app_admit_date")}
            />
          }
          DateTextBoxMed={
            <DateTextBox
              value={props.ORRequestSequence?.med_block_date || ""}
              minDate={formatDate(moment())}
              disabled={!(props.ORRequestSequence?.is_med_block && editable)}
              onChange={handleChangeDate("med_block_date")}
            />
          }
          DateTextBoxAnes={
            <DateTextBox
              value={props.ORRequestSequence?.anesthesia_date || ""}
              minDate={formatDate(moment())}
              disabled={!(props.ORRequestSequence?.is_anesthesia && editable)}
              onChange={handleChangeDate("anesthesia_date")}
            />
          }
          DateTextBoxOther={
            <DateTextBox
              value={props.ORRequestSequence?.other_date || ""}
              minDate={formatDate(moment())}
              disabled={!(props.ORRequestSequence?.is_other && editable)}
              onChange={handleChangeDate("other_date")}
            />
          }
          CardSurgeryTeam={
            <CardSurgeryTeam
              // callback
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              onChangeValue={handleChangeValue}
              onAddAnsTeam={handleAddAnsTeam}
              // data
              searchedItemListWithKey={props.searchedItemListWithKey}
              teams={props.ORRequestSequence?.teams || []}
              activeIndex={props.ORRequestSequence?.activeSurgeonTeam || 0}
              masterData={props.masterData}
              isReadOnly={!editable}
              // options
              masterOptions={props.masterOptions}
              orImplantOptions={props.ORRequestSequence?.orImplantOptions || []}
            />
          }
          // anesthesia_teams
          CardAnesthesiaTeam={
            !!props.ORRequestSequence?.anesthesia_teams?.length && (
              <CardAnesthesiaTeam
                ref={anesthesiaTeamRef}
                onEvent={props.onEvent}
                setProp={props.setProp}
                runSequence={props.runSequence}
                onChangeValue={handleChangeValue}
                // data
                searchedItemListWithKey={props.searchedItemListWithKey}
                teams={props.ORRequestSequence?.anesthesia_teams || []}
                activeIndex={props.ORRequestSequence?.activeAnesthesiaTeam || 0}
                // options
                isReadOnly={!editable}
              />
            )
          }
          CardORDetail={
            <CardORDetail
              setProp={props.setProp}
              runSequence={props.runSequence}
              // controller
              proxyController={props.proxyController}
              // data
              ORRequestSequence={props.ORRequestSequence}
              isNewEncounter={props.ORRequestSequence?.type === OR_TYPE.ELECTIVE_CASE}
              divisionId={props.divisionId}
              encounterId={props.encounterId}
              // options
              masterOptions={props.masterOptions}
              // callback
              onChangeValue={handleChangeValue}
              onChangeDate={handleChangeDate}
              canOrderBloodBank={!!props.setTab}
              onOrderBloodRequest={() => {
                setOpenModBloodBank(true);
              }}
              isReadOnly={!editable}
            />
          }
          SaveElement={
            <ButtonLoadCheckCustom
              // function
              setProp={props.setProp}
              onClick={handleSave}
              disabled={!editable}
              // data
              action={
                props.ORRequestSequence?.id
                  ? OPERATING_ORDER_ACTION.EDIT_REQUEST
                  : OPERATING_ORDER_ACTION.REQUEST
              }
              buttonLoadCheck={props.buttonLoadCheck}
              // config
              color={props.ORRequestSequence?.id ? "yellow" : "green"}
              title={props.ORRequestSequence?.id ? "แก้ไข" : "บันทึก และลงตารางนัดหมาย"}
              show={editable}
            />
          }
          ClearElement={
            editable && (
              <Button
                color="brown"
                style={{ marginRight: "1rem" }}
                onClick={handleClear}
                isReadOnly={!editable}
              >
                เคลียร์หน้าจอ
              </Button>
            )
          }
          PrintElement={
            <ButtonLoadCheckCustom
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              action={OPERATING_ORDER_ACTION.PRINT}
              buttonLoadCheck={props.buttonLoadCheck}
              // config
              color={"teal"}
              title={"พิมพ์รายการคำสั่งผ่าตัด"}
              show={props.ORRequestSequence?.id}
              // disabled={!editable}
            />
          }
          CancelConfirmElement={
            <ButtonLoadCheckCustom
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              action={OPERATING_ORDER_ACTION.CANCEL_CONFIRM}
              // buttonLoadCheck={props.buttonLoadCheck}
              // config
              color={"red"}
              title={"ยกเลิก Confirm"}
              show={showCancelConfirm}
              // disabled={!editable}
            />
          }
          CancelElement={
            <ButtonLoadCheckCustom
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              action={OPERATING_ORDER_ACTION.CANCEL}
              // buttonLoadCheck={props.buttonLoadCheck}
              // config
              color={"red"}
              title={"ยกเลิกนัดหมาย"}
              show={getAllowedAction(OPERATING_ORDER_ACTION.CANCEL) && props.isCancel}
              // disabled={!editable}
            />
          }
          ConfirmElement={
            <ButtonLoadCheckCustom
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              action={OPERATING_ORDER_ACTION.CONFIRM}
              buttonLoadCheck={props.buttonLoadCheck}
              // config
              color={"green"}
              title={"Confirm"}
              show={
                getAllowedAction(OPERATING_ORDER_ACTION.CONFIRM) &&
                props.isConfirm &&
                !showCancelConfirm
              }
              // disabled={!editable}
            />
          }
          ORRequestMessage={
            props.ORRequestSequence?.id && (
              <ORRequestMessage
                methodId={props.ORRequestSequence?.anesthesia_method}
                anesthesiaMethodList={props.masterData?.anesthesiaMethod}
                startDate={props.ORRequestSequence?.start_date || ""}
                startTime={props.ORRequestSequence?.start_time || ""}
                estimateHour={props.ORRequestSequence?.estimate_operation_hour}
                estimateMinute={props.ORRequestSequence?.estimate_operation_minute}
              />
            )
          }
        />
      </div>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OR_REQUEST}`, null);
        }}
        error={!props.ORRequestSequence?.modConfirm ? props.errorMessage?.[CARD_OR_REQUEST] : null}
        success={null}
      />

      <ModConfirm
        openModal={openModBloodBank}
        loading={props.loadingStatus?.["REQUEST_BLOOD_BANK"]}
        titleName="กรุณายืนยัน"
        size="tiny"
        // @ts-ignore
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.1rem",
              fontWeight: "bold",
              lineHeight: "2em",
              margin: "-1rem 0 -1.5rem",
            }}
          >
            <div>ท่านยืนยันให้ระบบบันทึกข้อมูล</div>
            <div>ใบขอนัดหมายผ่าตัด (OR Request)</div>
            <div>ก่อนดำเนินการจองเลือด ใช่หรือไม่</div>
          </div>
        }
        approveButtonText="ยืนยัน"
        denyButtonText="ปิดหน้าต่าง"
        l
        onApprove={handleConfirmOrderBloodBank}
        onDeny={() => {
          setOpenModBloodBank(false);
        }}
      />

      {props.ORRequestSequence?.modConfirm && (
        <ModConfirmCustom
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          userTokenize={props.userTokenize}
          error={props.errorMessage?.[CARD_OR_REQUEST]}
          loading={props.loadingStatus?.[props.ORRequestSequence?.modConfirm?.card_action]}
          // options
          options={props.masterOptions?.orCancelNote}
          // callback
          onDeny={handleCloseModConfirm}
          onApprove={handleConfirm}
        />
      )}
    </div>
  );
};

/* ------------------------------------------------------ */

/*                 ButtonLoadCheckCustom;                 */

/* ------------------------------------------------------ */
const ButtonLoadCheckCustom = (props: any) => {
  return (
    <>
      {props.show && (
        <ButtonLoadCheck
          // function
          disabled={props.disabled}
          setProp={props.setProp}
          onClick={props.onClick(props.action)}
          // data
          className="ignore-prevent"
          paramKey={`${CARD_OR_REQUEST}_${props.action}`}
          buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_OR_REQUEST}_${props.action}`]}
          // config
          color={props.color}
          name={`_${props.action}`}
          // size="small"
          style={{ width: "100%", marginRight: "1rem" }}
          title={props.title}
        />
      )}
    </>
  );
};

/* ------------------------------------------------------ */

/*                    ModConfirmCustom;                   */

/* ------------------------------------------------------ */
const ModConfirmCustom = (props: any) => {
  const [currentValue, setCurrentValue] = useState<any>("");

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleApprove = () => {
    props.onApprove?.(currentValue);
  };

  return (
    <ModConfirmAuthen
      {...{
        title: "ยกเลิกนัดหมาย",
        questionDetail: "โปรดระบุเหตุผลในการยกเลิก",
        titleColor: "red",
        userEditQuestion: " ",
        buttonLeftLabel: "ใช่",
        buttonRightLabel: "ไม่",
        buttonLeftColor: "green",
        buttonRightColor: "red",
        size: "small",
        // disableButtonLeft: !props.userTokenize?.employeeName || !currentValue,
        isRequiredQuestionDetail: true,
        isUserEditQuesion: true,
        isErrMsg: true,
        multiple: false,
      }}
      // callback
      onEvent={props.onEvent}
      setProp={props.setProp}
      handleChange={handleChange}
      onButtonLeftClick={handleApprove}
      onButtonRightClick={props.onDeny}
      onClose={props.onDeny}
      // options
      options={props.options}
      // data
      open={true}
      errorMessage={props.error}
      userTokenize={props.userTokenize}
      currentValue={currentValue as any}
      isButtonBasic={true}
      buttonLeftLoading={props.loading}
    />
  );
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

function handlePreventClick(e: any) {
  console.log("PreventClickEvent handlePreventClick e: ", e);
  e.target.blur();
  e.preventDefault();
  e.stopPropagation();
}

export const PreventClickEvent = (editable: boolean, target: HTMLDivElement) => {
  const children = Array.from(
    target.querySelectorAll("button, input, textarea, .dropdown, .menu, .checkbox, .icon")
  ) as HTMLElement[];

  if (!editable) {
    for (const elm of children) {
      if (!elm.className.includes("ignore-prevent")) {
        ["click", "keydown", "focus"].forEach((event) => {
          elm.removeEventListener(event, handlePreventClick);
          elm.addEventListener(event, handlePreventClick);
          elm.tagName !== "INPUT" && (elm.style.pointerEvents = "none");
        });
      }
    }
  } else {
    for (const elm of children) {
      ["click", "keydown", "focus"].forEach((event) => {
        elm.removeEventListener(event, handlePreventClick);
        elm.tagName !== "INPUT" && (elm.style.pointerEvents = "");
      });
    }
  }
};

CardORRequest.displayName = "CardORRequest";
export default React.memo(CardORRequest);
