import React, { CSSProperties } from "react";
import { Modal, Segment, Button, SemanticCOLORS } from "semantic-ui-react";
import "../../css/ModInfo.css";

type ModMedReconcileAlertProps = {
  // medReconcileCheck?: any;
  open?: boolean;
  onClose?: any;
  onApprove?: any;
  btnLoading?: boolean;
  children?: JSX.Element | string;
};

const ModMedReconcileAlert = (props: ModMedReconcileAlertProps) => {
  const titleColor = "yellow";
  const titleName = "แจ้งเตือน";

  let styles: any = {
    header: {
      lineHeight: "1.3em",
    },
    button: {
      marginTop: "10px",
    },
  };
  // console.log(" style", styles.header );

  return (
    <Modal open={props.open} onClose={props.onClose} size="mini">
      <Segment inverted className={"modHeader yellow"} style={styles.header}>
        {titleName}
      </Segment>
      <Segment padded align="center" className="modContent">
        <div
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "larger",
            lineHeight: 1.5,
          }}
        >
          {props.children}
        </div>
        <Button
          basic
          color="green"
          className="approveButton"
          style={styles.button}
          onClick={props.onApprove}
          loading={props.btnLoading}
        >
          ตกลง
        </Button>
      </Segment>
    </Modal>
  );
};

const TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  CANCEL: "cancel",
};

// ModInfo.propTypes = {
//   color: PropTypes.oneOfType([null, PropTypes.string]),
//   type: PropTypes.string,
//   open: PropTypes.bool,
//   onApprove: PropTypes.func,
//   alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   btnText: PropTypes.string,
//   titleName: PropTypes.string,
//   titleColor: PropTypes.string,
//   size: PropTypes.string,
//   className: PropTypes.string,
//   closeOnDimmerClick: PropTypes.bool,
//   onClose: PropTypes.func,
//   duration: PropTypes.number,
//   buttonColor: PropTypes.string,
//   btnLoading: PropTypes.bool,
//   textAlign: PropTypes.string,
//   blueBackground: PropTypes.bool,
// };

ModMedReconcileAlert.defaultProps = {
  color: "",
  type: "",
  titleName: "",
  titleColor: "",
  blueBackground: false,
  open: false,
  duration: 0,
  buttonColor: "",
  textAlign: "",
  onApprove: () => {},
  alertText: "",
  btnText: "ตกลง",
  size: "mini",
  className: "",
  closeOnDimmerClick: true,
  onClose: () => {},
  btnLoading: false,
  style: {},
};

export default ModMedReconcileAlert;
