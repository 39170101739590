export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        width: `auto`,
        bold: `true`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decoration: ``,
        decorationStyle: ``,
        alignment: `center`,
        pageBreak: ``,
        fontSize: `18`,
        text: `Medical Certificate`,
        color: ``,
      },
      {
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        bold: false,
        width: `auto`,
        decoration: ``,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            width: 420,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            text: ``,
          },
          {
            text: `Date `,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            bold: true,
          },
          {
            fontSize: 15,
            color: ``,
            width: 5,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `center`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: true,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            text: props.items?.formatCreatedDate,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        text: ` `,
        width: `auto`,
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        fontSize: 15,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 40,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            text: `Physician’s name `,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            width: 10,
            text: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                text: props.items?.doctorNameEn,
                bold: false,
                preserveLeadingSpaces: true,
                alignment: `left`,
                pageBreak: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
              },
              {
                pageBreak: ``,
                fontSize: 15,
                alignment: `left`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                preserveLeadingSpaces: true,
                width: `auto`,
                color: ``,
                decorationColor: ``,
                decoration: ``,
                text: `...........................................................`,
              },
            ],
          },
          {
            decorationColor: ``,
            width: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
          },
          {
            text: `License No.`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            color: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            bold: false,
            width: 10,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                width: `auto`,
                text: props.items?.doctorCertNumber ? props.items?.doctorCertNumber : " ",
                alignment: `left`,
                decoration: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                fontSize: 15,
                color: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                bold: false,
              },
              {
                pageBreak: ``,
                width: `auto`,
                color: ``,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                text: `...........................................................`,
                preserveLeadingSpaces: true,
                bold: false,
                alignment: `left`,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: `has examined `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
          },
          {
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 10,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                color: ``,
                bold: false,
                width: `auto`,
                preserveLeadingSpaces: true,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                decorationColor: ``,
              },
              {
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                fontSize: 15,
                alignment: `left`,
                decorationColor: ``,
                text: `.................................................................................................................................................................................`,
                margin: [0, 0, 0, 0],
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            text: `Identification / Passport number / Other `,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                decorationColor: ``,
                bold: false,
                width: `auto`,
                alignment: `left`,
              },
              {
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                color: ``,
                fontSize: 15,
                text: `................................................................................................................................`,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `Age `,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            width: `auto`,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            decorationStyle: ``,
            width: 10,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                alignment: `left`,
                decorationStyle: ``,
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
                color: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                decoration: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
              },
              {
                text: `...........................................................................`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                bold: false,
                alignment: `left`,
                decoration: ``,
                width: `auto`,
                pageBreak: ``,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            width: 15,
            alignment: `center`,
            text: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            text: ` Hospital Number `,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                fontSize: 15,
                width: `auto`,
                alignment: `left`,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
                decoration: ``,
                text: props.items?.patientInfo?.hn,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
              },
              {
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                fontSize: 15,
                bold: false,
                color: ``,
                decoration: ``,
                decorationColor: ``,
                text: `...........................................................................`,
                alignment: `left`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            text: `as an`,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            color: ``,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            stack: [
              {
                color: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                bold: false,
                decorationColor: ``,
                decoration: ``,
                text: `${props.items?.patientVisitType[1]} ${
                  props.items?.encounterInfo?.type === "OPD"
                    ? `(EN: ${props.items?.encounterInfo?.number})`
                    : `(AN: ${props.items?.encounterInfo?.number})`
                }`,
                decorationStyle: ``,
                fontSize: 15,
                alignment: `left`,
                margin: [0, 0, 0, -15],
              },
              {
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                width: `auto`,
                bold: false,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                alignment: `left`,
                decorationStyle: ``,
                color: ``,
                text: `............................................................................`,
                fontSize: 15,
              },
            ],
          },
          {
            pageBreak: ``,
            fontSize: 15,
            text: `on`,
            width: `auto`,
            bold: false,
            margin: [-54, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            text: ``,
            width: 10,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                text: props.items?.patientExamDate[1],
                margin: [0, 0, 0, -15],
                bold: false,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                alignment: `left`,
                fontSize: 15,
                decorationColor: ``,
                decorationStyle: ``,
                width: `auto`,
                color: ``,
              },
              {
                margin: [0, 0, 0, 0],
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                text: `......................................................................................................`,
                alignment: `left`,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            text: `Chief Complaint :`,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                width: `auto`,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                bold: false,
                text:
                  props.items?.chiefComplaint == null || props.items?.chiefComplaint === ""
                    ? " "
                    : props.items?.chiefComplaint,
              },
              {
                pageBreak: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                color: ``,
                alignment: `left`,
                decoration: ``,
                decorationStyle: ``,
                fontSize: 15,
                decorationColor: ``,
                text: `...........................................................................................................................................................................`,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            text: `Diagnosis :`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            text: ``,
            width: 10,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                decoration: ``,
                decorationColor: ``,
                text:
                  props.items?.diagnosis == null || props.items?.diagnosis === ""
                    ? " "
                    : props.items?.diagnosis,
                width: `auto`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                bold: false,
                alignment: `left`,
                pageBreak: ``,
              },
              {
                fontSize: 15,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                text: `........................................................................................................................................................................................`,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `left`,
                bold: false,
                color: ``,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `Comment :`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
          },
          {
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            text: ``,
            color: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
                decorationColor: ``,
                color: ``,
                fontSize: 15,
                alignment: `left`,
                bold: false,
                decorationStyle: ``,
                pageBreak: ``,
                decoration: ``,
                width: `auto`,
              },
              {
                fontSize: 15,
                decoration: ``,
                color: ``,
                width: `auto`,
                text: `......................................................................................................................................................................................`,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        width: `auto`,
        bold: false,
        decorationColor: ``,
        text: `...............................................................................................................................................................................................................`,
        decoration: ``,
        pageBreak: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      {
        bold: false,
        text: `...............................................................................................................................................................................................................`,
        decorationStyle: ``,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        width: `auto`,
        color: ``,
        decoration: ``,
      },
      {
        text: ` `,
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        bold: false,
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            bold: false,
            text: `Patient required period of absence for`,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            decorationColor: ``,
            width: 10,
            alignment: `left`,
            bold: false,
            decoration: ``,
          },
          {
            stack: [
              {
                color: ``,
                decorationStyle: ``,
                alignment: `center`,
                decoration: ``,
                preserveLeadingSpaces: true,
                text: props.items?.dayDuration,
                bold: false,
                decorationColor: ``,
                width: `auto`,
                pageBreak: ``,
                fontSize: 15,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............`,
                margin: [0, 0, 0, 0],
                width: `auto`,
                decoration: ``,
                decorationColor: ``,
                alignment: `left`,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            width: 5,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `days`,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            bold: false,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            width: 10,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            text: `for`,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            text: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            width: 10,
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                bold: false,
                color: ``,
                decorationColor: ``,
                fontSize: 15,
                pageBreak: ``,
                text: props.items?.patientAbsenceStart ? props.items?.patientAbsenceStart : " ",
              },
              {
                fontSize: 15,
                text: `........................................`,
                pageBreak: ``,
                decorationColor: ``,
                bold: false,
                decoration: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                alignment: `left`,
                color: ``,
              },
            ],
            width: `auto`,
          },
          {
            width: 5,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            text: `to`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            text: ``,
            width: 10,
            bold: false,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                bold: false,
                color: ``,
                width: `auto`,
                fontSize: 15,
                pageBreak: ``,
                text: props.items?.patientAbsenceEnd ? props.items?.patientAbsenceEnd : " ",
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                alignment: `center`,
              },
              {
                decoration: ``,
                color: ``,
                pageBreak: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationStyle: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                width: `auto`,
                text: `......................................`,
                bold: false,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        decorationColor: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        width: `auto`,
        bold: false,
        text: ` `,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        fontSize: 15,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        text: ` `,
        color: ``,
        bold: false,
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
        width: `auto`,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: ` `,
        bold: false,
        decorationColor: ``,
        decoration: ``,
        alignment: `left`,
        fontSize: 15,
        color: ``,
        width: `auto`,
        pageBreak: ``,
      },
      {
        pageBreak: ``,
        bold: false,
        color: ``,
        decoration: ``,
        width: `auto`,
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            stack: [
              {
                text: `.......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
              {
                text: `(${props.items?.doctorNameEn})`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
              {
                text: `Physician’s Signature`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            stack: [
              {
                text: `.......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
              {
                text: `(${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                })`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
              {
                text: `Patient’s Signature`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
