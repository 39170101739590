import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    content: [
      {
        alignment: `center`,
        decorationColor: ``,
        decorationStyle: ``,
        text: `หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ`,
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        fontSize: `18`,
        color: ``,
        bold: `true`,
      },
      {
        bold: false,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        decoration: ``,
        text: ` `,
        decorationColor: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            width: 30,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว) `,
            color: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            width: 5,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ` `,
            fontSize: 15,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                fontSize: 15,
                width: `auto`,
                decoration: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationColor: ``,
                color: ``,
                bold: false,
                text: props.items?.formatPatient,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
              },
              {
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                decorationColor: ``,
                color: ``,
                bold: false,
                text: `.......................................................................................................................................................`,
                alignment: `left`,
                width: `auto`,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            text: `ขอแสดงความยินยอมโดยสมัครใจให้ นายแพทย์/แพทย์หญิง`,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                decoration: ``,
                color: ``,
                text: props.items?.formatDoctor,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                bold: false,
                fontSize: 15,
              },
              {
                color: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decoration: ``,
                pageBreak: ``,
                text: `.................................................................................................................`,
                width: `auto`,
                bold: false,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            text: `และพนักงานของโรงพยาบาลที่ได้รับมอบหมายให้มีส่วนร่วมในการดูแลอาการของข้าพเจ้า ซึ่งข้าพเจ้าได้รับการอธิบายว่า....................`,
            decoration: ``,
          },
        ],
      },
      {
        text: `...................................................................................................................................................................................................................`,
        color: ``,
        fontSize: 15,
        width: `auto`,
        bold: false,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 30,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
          },
          {
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `ข้าพเจ้าเข้าใจและยินยอมให้มีการดำเนินการรักษาโดยการผ่าตัด, การรักษาโดยใช้ยา, และ/หรือการกระทำการตรวจวินิจฉัย`,
            width: `auto`,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            text: `ตามที่ได้มีการวางแผนไว้สำหรับข้าพเจ้าคือ : `,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
          },
        ],
      },
      {
        color: ``,
        pageBreak: ``,
        decorationStyle: `dotted`,
        fontSize: 15,
        text: props.items?.formatOperation,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: props.items?.enableDecorationOperation ? `underline` : ``,
        bold: false,
        alignment: `justify`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            width: 30,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
          },
          {
            color: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            text: `ข้าพเจ้าได้ทราบรายละเอียดเกี่ยวกับการผ่าตัด, การใช้ยา, และ/หรือการตรวจวินิจฉัยรวมไปถึงประโยชน์ ความเสี่ยง`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
          },
        ],
      },
      {
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        alignment: `left`,
        text: `ผลข้างเคียง และ/หรือผลที่อาจจะเกิดขึ้นได้ในภายหลัง และได้อภิปรายกับแพทย์เกี่ยวกับแพทย์เกี่ยวกับผลสำเร็จ ซึ่งขึ้นอยู่`,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
      },
      {
        decorationColor: ``,
        color: ``,
        bold: false,
        preserveLeadingSpaces: true,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        text: `กับปัจจัยจากตัวข้าพเจ้าเองและปัจจัยอื่นๆ รวมทั้งปัญหาที่อาจเกิดขึ้นในระยะฟื้นตัว อีกทั้งทางเลือกอื่นๆ ที่เป็นไปได้ สำหรับ`,
        fontSize: 15,
        decorationStyle: ``,
      },
      {
        text: `กรณีของข้าพเจ้าเป็นอย่างดี`,
        decorationColor: ``,
        decoration: ``,
        bold: false,
        pageBreak: ``,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 30,
            color: ``,
          },
          {
            color: ``,
            text: `โดยแพทย์ `,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            width: 5,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            stack: [
              {
                bold: false,
                margin: [0, 0, 0, -15],
                width: `auto`,
                fontSize: 15,
                alignment: `left`,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                decoration: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                text: props.items?.formatDoctorFullName,
              },
              {
                decorationColor: ``,
                bold: false,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decoration: ``,
                text: `....................................................................................................................................................................................`,
                pageBreak: ``,
                color: ``,
                alignment: `left`,
                width: `auto`,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            width: 30,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            bold: false,
            width: 5,
          },
          {
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            text: `ได้รับเอกสารคำแนะนำเรื่อง `,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            text: `.....................................................................`,
          },
          {
            color: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            width: 10,
          },
          {
            bold: false,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: `หมายเลข `,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
          },
          {
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            width: 10,
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `......................................................`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 30,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            text: `ข้าพเจ้าขอแสดงความยินยอมให้แพทย์และ/หรือผู้ช่วยแพทย์ สามารถกระทำการรักษา/หัตถการ เพิ่มเติม/ปรับเปลี่ยนด้วย`,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        text: `เหตุผลซึ่งแสดงถึงความจำเป็นในการวินิจฉัย/การรักษาดังกล่าว อาทิ เช่น การใช้ยาระงับความรู้สึก การให้โลหิตและ/หรือผลิตภัณฑ์`,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        bold: false,
        width: `auto`,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        text: `ของโลหิต การวินิจฉัยทางรังสีวิทยาและพยาธิวิทยาการตัดชิ้นเนื้อเยื่อหรือส่วนของอวัยวะซึ่งกระทำโดยแพทย์ผู้ทำการผ่าตัด ได้รับการ`,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        text: `ยินยอมจากข้าพเจ้า หากการวินิจฉัย หรือการรักษาดังกล่าว กระทำไปโดยคำนึงถึงผลประโยชน์ของตัวข้าพเจ้า`,
        color: ``,
        alignment: `left`,
        pageBreak: ``,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 30,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            text: `ข้าพเจ้าเข้าใจว่าไม่มีการรับประกันในผลของการวินิจฉัยต่อข้าพเจ้าในครั้งนี้แต่อย่างใด`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            width: 30,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: `ในกรณีที่เจ้าหน้าที่บุคลากรทางการแพทย์ถูกของมีคมทิ่มตำโดยไม่เจตนา และ/หรือ มีผลให้เกิดการสัมผัสโดนเลือดของ`,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        alignment: `left`,
        decoration: ``,
        color: ``,
        width: `auto`,
        decorationColor: ``,
        bold: false,
        text: `ข้าพเจ้าบริเวณบาดแผลนั้นๆ ข้าพเจ้ายินยอมให้ตรวจเลือดเพื่อตรวจหาเชื้อโรคติดเชื้อ และเชื้อเอชไอวี (HIV)`,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            text: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            width: 30,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: `ณ ที่นี้ข้าพเจ้าแสดงความยินยอมเพื่อรับการรักษาดังกล่าวข้างต้น`,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        bold: false,
        width: `auto`,
        fontSize: 15,
        text: ` `,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            alignment: `center`,
            decoration: ``,
            width: 200,
            bold: false,
            text: `ลงนาม........................................................  `,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            width: 80,
            bold: false,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            text: `  `,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            text: `พยาน 1 ลงนาม........................................................ `,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            color: ``,
            alignment: `center`,
            decorationStyle: ``,
            width: 200,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                width: `auto`,
                text: props.items?.full_name,
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                alignment: `center`,
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
              },
              {
                width: 200,
                text: `(.............................................................)`,
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                alignment: `center`,
                decorationColor: ``,
                decorationStyle: ``,
              },
            ],
            width: 200,
          },
          {
            width: 80,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            text: `(.............................................................)`,
            width: 200,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
        ],
      },
      {
        width: `auto`,
        fontSize: 15,
        bold: false,
        text: ` `,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            width: 200,
            text: `แพทย์ลงนาม........................................................`,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `center`,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            bold: false,
            pageBreak: ``,
            width: 80,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `พยาน 2 ลงนาม........................................................`,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            width: 200,
            bold: false,
            fontSize: 15,
            alignment: `center`,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: 200,
            bold: false,
            preserveLeadingSpaces: true,
            text: props.items?.formatDoctorFullName,
            color: ``,
          },
          {
            color: ``,
            width: 80,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            alignment: `center`,
            color: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            width: 200,
            decoration: ``,
            decorationColor: ``,
            text: `(.............................................................)`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 200,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            color: ``,
            text: `(แพทย์ผู้ให้คำอธิบาย)`,
          },
          {
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 80,
            bold: false,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            width: 200,
            bold: false,
            decoration: ``,
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์) `,
            decorationColor: ``,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            decorationStyle: ``,
            pageBreak: ``,
            text: `วันที่........................เวลา..................`,
            bold: false,
            color: ``,
            width: 200,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        pageBreak: `before`,
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        color: ``,
        decorationColor: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        text: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            bold: `true`,
            alignment: `left`,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: `underline`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 30,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            width: `auto`,
            color: ``,
            text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ (Consent for Surgery or Special`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        color: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        text: `Procedure) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้แก่ผู้ป่วย/ผู้แทนผู้ป่วยทราบ`,
        pageBreak: ``,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        bold: false,
      },
      {
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        text: ` `,
        fontSize: 15,
        width: `auto`,
        bold: false,
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            alignment: `center`,
            decorationColor: ``,
            text: `ภาษาที่แปล............................................`,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            width: 200,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            width: 80,
            decoration: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            text: ` `,
            decorationStyle: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            width: 200,
            fontSize: 15,
            alignment: `center`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `ผู้แปลลงนาม........................................................ `,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            width: 280,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            width: 200,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `center`,
            bold: false,
            decoration: ``,
            text: `(.............................................................)`,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        text: ` `,
        width: `auto`,
      },
      {
        color: ``,
        bold: `true`,
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        pageBreak: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            bold: false,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            width: 5,
          },
          {
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            color: ``,
            width: 5,
            fontSize: 15,
          },
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            width: 5,
            alignment: `left`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            width: 5,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ  (ตามคำสั่งศาล)`,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            text: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            width: 15,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            bold: false,
            fontSize: 15,
          },
          {
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: 15,
        text: ` `,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            text: `ความสัมพันธ์กับผู้ป่วย.........................................................................................................................`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            color: ``,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย................................................................................ `,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            text: `เบอร์โทรศัพท์.......................................................................................................................................`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            text: `อีเมล.....................................................................................................................................................`,
            decoration: ``,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        alignment: `center`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decoration: ``,
        fontSize: `18`,
        pageBreak: `before`,
        bold: `true`,
        color: ``,
        decorationStyle: ``,
        text: `คำแนะนำการรับโลหิตและ/หรือส่วนประกอบของโลหิต`,
        width: `auto`,
      },
      {
        width: `auto`,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        bold: false,
        decoration: ``,
        fontSize: 15,
        text: ` `,
        decorationColor: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        bold: `true`,
        fontSize: 15,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        color: ``,
        text: `ข้อมูลเกี่ยวกับการรับโลหิตและ/หรือส่วนประกอบของโลหิต`,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            width: 30,
            decorationStyle: ``,
          },
          {
            bold: false,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            text: `เนื่องจากท่านหรือบุคคลที่ท่านเป็นผู้แทน อาจมีความจำเป็นต้องได้รับโลหิต และ/หรือส่วนประกอบของโลหิตเพื่อการรักษา`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        text: `เอกสารฉบับนี้แสดงถึงวิธีการให้และโอกาสเสี่ยงที่อาจจะเกิดขึ้นจากการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต ดังนี้`,
        fontSize: 15,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        color: ``,
        pageBreak: ``,
        decoration: ``,
        text: ` `,
        fontSize: 15,
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        bold: false,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
        pageBreak: ``,
        text: `วิธีการให้โลหิตและ/หรือส่วนประกอบของโลหิต`,
        width: `auto`,
        bold: `true`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        color: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: 30,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            text: `การให้โลหิต และหรือส่วนประกอบของโลหิต จะให้ไหลผ่านทางหลอดเลือดดำด้วยวิธีการปลอดเชื้อ และมีการตรวจสอบ`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        alignment: `left`,
        decoration: ``,
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        color: ``,
        text: `ความถูกต้องว่าได้ให้ถูกคนและถูกชนิด ตลอดจนมีการเฝ้าระวังอาการและความผิดปกติต่างๆ ที่อาจเกิดขึ้นได้ในระหว่างการได้รับ`,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        fontSize: 15,
        text: `หรือหลังการได้รัลโลหิต และส่วนประกอบของโลหิต`,
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        alignment: `left`,
        width: `auto`,
        decoration: ``,
        color: ``,
        bold: false,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        text: ` `,
        preserveLeadingSpaces: true,
      },
      {
        bold: `true`,
        text: `โอกาสเสี่ยงจากการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต`,
        color: ``,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            width: 5,
            bold: false,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `โดยทั่วไปถือได้ว่าการได้รับโลหิตและ/หรือส่วนประกอบของโลหิต มีอัตราเสี่ยงน้อย ได้แก่`,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: 10,
            text: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            text: `•`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            text: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `ปฏิกิริยาชั่วคราวและไม่รุนแรง ได้แก่ การมีรอยช้้ำเขียว บวม และเจ็บในตำแหน่งที่แทงเข็ม นอกจากนี้อาจมีอาการ`,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            width: 20,
            color: ``,
            fontSize: 15,
          },
          {
            text: `ข้างเคียงจากส่วนประกอบของโลหิต ได้แก่ อาการปวดศีรษะ ไข้ หนาวสั่น หรือผื่นคันที่ผิวหนัง`,
            color: ``,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            fontSize: 15,
            width: 10,
            text: ``,
          },
          {
            alignment: `center`,
            bold: false,
            text: `•`,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            decoration: ``,
            text: `ปฏิกิริยารุนแรง ซึ่งอาจถึงแก่ชีวิตได้ แต่มีโอกาสเกิดขึ้นได้น้อยมาก เนื่องจากโลหิตและ/หรือส่วนประกอบของโลหิต`,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            width: 10,
            color: ``,
          },
          {
            text: ``,
            fontSize: 15,
            width: `ทุกหน่วยได้รับการตรวจสอบการเข้ากันได้กับผู้ป่วยอย่างรอบคอบแล้ว`,
            decoration: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            width: 10,
          },
          {
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            text: `•`,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            text: `การติดเชื้อ การติดเชื้อโรคบางชนิดอาจเกิดจากการได้รับโลหิตและ/หรือส่วนประกอบของโลหิตได้ เช่น เชื้อไวรัสตับ`,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 20,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            text: `อักเสบ มาลาเรีย เชื้อเอชไอวี เป็นต้น อย่างไรก็ดี อัตราการเกิดขึ้นได้น้อยมาก เนื่องจากโลหิตและส่วนประกอบของโลหิต`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            text: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            width: 10,
            decoration: ``,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            width: `ทุกหน่วยได้รับการตรวจกรองการติดเชื้อดังกล่าวตามวิธีมาตรฐานระดับสากลแล้ว`,
            alignment: `left`,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    pageSize: `A4`,
    images: injectImages(props.items?.haveHeader),
  };
}
