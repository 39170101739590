import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Icon, Label, Popup } from "semantic-ui-react";

import { getDrugStatus, getFoodNOtherStatus } from "./CardSubAllergyHistory";

import { ADR_TYPES } from "./sequence/Allergy";

// Types
type SubAdrPopupProps = {
  runSequence: (e: any) => any;
  AllergySequence: Record<string, any> | null;
  // data
  patientId: number | null;
};

type Styles = { [key in "close" | "popup"]: CSSProperties };

// Style
const styles: Styles = {
  close: { position: "absolute", right: "3px", top: "6px" },
  popup: { border: "1px solid #db0808", cursor: "pointer", maxWidth: "375px", minWidth: "250px" },
};

// Const
const IMAGES = {
  personAlert: "/static/images/drugOrder/person-alert-filled.png",
};

const POPUP_OFFSET: [number, number] = [7.5, -2.5];

const SubAdrPopup = (props: SubAdrPopupProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Callback memo
  const filterADR = useCallback(
    (adrList: any[], type: any) => adrList.filter((item) => item.type_name_name === type),
    []
  );

  // Effect memo
  const groupADR = useMemo(() => {
    const adrList: any[] = props.AllergySequence?.adrList || [];

    return {
      drug: filterADR(adrList, ADR_TYPES.DRUG),
      food: filterADR(adrList, ADR_TYPES.FOOD),
      other: filterADR(adrList, ADR_TYPES.OTHER),
    };
  }, [props.AllergySequence?.adrList]);

  const showAdr = useMemo(
    () => groupADR.drug.length > 0 || groupADR.food.length > 0 || groupADR.other.length > 0,
    [groupADR]
  );

  // Effect
  useEffect(() => {
    setIsOpen(showAdr);
  }, [showAdr]);

  useEffect(() => {
    const isFetchAllergy = props.patientId && !props.AllergySequence;

    if (isFetchAllergy) {
      props.runSequence({
        sequence: "Allergy",
        noEmr: true,
        patient: props.patientId,
        restart: true,
      });
    }

    return () => {
      if (isFetchAllergy) {
        props.runSequence({ sequence: "Allergy", clear: true });
      }
    };
  }, [props.patientId]);

  // CAllback
  // * "Amoxicillin, Arcoxia (Etoricoxib), Aspirin, lbuprofen, "
  const removeParenthesesContent = (text: string) => text.replaceAll(/\s*\(.*?\)\s*/g, "").trim();

  const concatAdrShortInfo = (data: Record<string, any>[] = []) => {
    const medications = new Set();

    for (const entry of data) {
      const text: string = entry.adr_short_info || "";

      if (text) {
        for (const med of text.split(",")) {
          const cleanedMed = removeParenthesesContent(med);

          if (cleanedMed) {
            medications.add(cleanedMed);
          }
        }
      }
    }

    return [...medications].join(", ");
  };

  const handleClosePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpenPopup = useCallback(() => {
    setIsOpen(true);
  }, []);

  // Memo
  const statusLabel = useMemo(
    () => ({
      drug: getDrugStatus(groupADR.drug.filter((item) => !item.is_inactive)),
      food: getFoodNOtherStatus(groupADR.food, "food"),
      other: getFoodNOtherStatus(groupADR.other, "other"),
    }),
    [groupADR]
  );

  const adrList = useMemo(
    () =>
      [
        {
          code: "drug" as const,
          label: "ยา",
        },
        { code: "food" as const, label: "อาหาร" },
        { code: "other" as const, label: "สารอื่นๆ" },
      ].map((item) => {
        const info = concatAdrShortInfo(groupADR[item.code]);
        const isNKA = statusLabel[item.code].title === "ปฏิเสธแพ้ยา";

        return { ...item, value: isNKA ? "NKA" : info || "ไม่มีข้อมูลการบันทึก" };
      }),
    [groupADR, statusLabel]
  );

  const trigger = useMemo(
    () => (
      <img
        alt="person alert filled"
        src={IMAGES.personAlert}
        style={{ cursor: "pointer", margin: "0.5rem 0 0", width: "30px" }}
      />
    ),
    []
  );

  const content = useMemo(
    () => (
      <div>
        <Icon color="red" name="close" style={styles.close} onClick={handleClosePopup} />
        <Label color="red">ADR</Label>
        {adrList.map((item) => (
          <div key={`adr-${item.label}`} style={{ color: "#db0808", margin: "0.5rem 0" }}>
            <strong>
              <span>{item.label} :</span>
            </strong>{" "}
            {item.value}
          </div>
        ))}
      </div>
    ),
    [adrList]
  );

  return (
    <div>
      {showAdr && (
        <Popup
          content={content}
          offset={POPUP_OFFSET}
          open={isOpen}
          position="bottom right"
          style={styles.popup}
          trigger={trigger}
          on="click"
          onOpen={handleOpenPopup}
        />
      )}
    </div>
  );
};

export default React.memo(SubAdrPopup);
