import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  const diagnosisCheck = (diagnosis: any) => {
    if (diagnosis && diagnosis.trim().length > 0) {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: ` `,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    } else {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: `...............................................................................................................................................................................................................`,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
        {
          alignment: `left`,
          preserveLeadingSpaces: true,
          width: `auto`,
          fontSize: 15,
          decoration: ``,
          decorationStyle: ``,
          decorationColor: ``,
          color: ``,
          margin: [0, 0, 0, 0],
          text: `...............................................................................................................................................................................................................`,
          bold: false,
          pageBreak: ``,
        },
      ];
    }
  };

  return {
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        fontSize: `18`,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        color: ``,
        text: `Medical Certificate`,
        bold: `true`,
        alignment: `center`,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        pageBreak: ``,
        alignment: `center`,
        bold: `true`,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: `18`,
        text: `Fit for Travel`,
        width: `auto`,
      },
      {
        color: ``,
        decorationColor: ``,
        bold: false,
        width: `auto`,
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        text: ` `,
        decoration: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            width: 420,
            bold: false,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            text: `Date ${props.items?.formatCreatedDate}`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: true,
            fontSize: 15,
            width: `auto`,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        alignment: `left`,
        bold: false,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
        text: `To Whom It May Concern:`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            text: ``,
            width: 30,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            text: `This is certify that `,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            alignment: `left`,
          },
          {
            stack: [
              {
                bold: false,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                color: ``,
                alignment: `left`,
                pageBreak: ``,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                decoration: ``,
                decorationColor: ``,
              },
              {
                alignment: `left`,
                decoration: ``,
                width: `auto`,
                text: `.............................................................................................................................................................`,
                decorationStyle: ``,
                bold: false,
                pageBreak: ``,
                color: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                fontSize: 15,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            text: `Identification / Passport number / Others`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
          },
          {
            alignment: `left`,
            width: 10,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            text: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationColor: ``,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                alignment: `left`,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
                pageBreak: ``,
                color: ``,
                width: `auto`,
                bold: false,
              },
              {
                pageBreak: ``,
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                margin: [0, 0, 0, 0],
                bold: false,
                fontSize: 15,
                alignment: `left`,
                preserveLeadingSpaces: true,
                text: `..............................................................................................................................`,
                decoration: ``,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationColor: ``,
            text: `Age `,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            width: 10,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
                bold: false,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
              },
              {
                decoration: ``,
                bold: false,
                fontSize: 15,
                text: `............................................................................`,
                decorationStyle: ``,
                decorationColor: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            width: 10,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            text: ` Hospital Number `,
            color: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            width: 10,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                color: ``,
                alignment: `left`,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                text: props.items?.patientInfo?.hn,
                bold: false,
              },
              {
                text: `..............................................................................`,
                fontSize: 15,
                decoration: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
                bold: false,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            text: `has examined and treated at our hospital as an :`,
          },
          {
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            width: 10,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            stack: [
              {
                text:
                  props.items?.encounterInfo?.type === "OPD" ? "Out-patient" : "In-patient",
                bold: false,
                width: `auto`,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                color: ``,
                decorationColor: ``,
                decorationStyle: ``,
                fontSize: 15,
                pageBreak: ``,
              },
              {
                text: `.................................................`,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
                width: `auto`,
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                fontSize: 15,
              },
            ],
            width: `auto`,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            margin: [0, 0, 0, 0],
            text: `on`,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                decorationColor: ``,
                decorationStyle: ``,
                fontSize: 15,
                text: props.items?.examDate ? props.items?.examDate : ` `,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                width: `auto`,
                alignment: `left`,
                color: ``,
                margin: [0, 0, 0, -15],
                bold: false,
                decoration: ``,
              },
              {
                bold: false,
                decorationStyle: ``,
                width: `auto`,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                text: `.................................................`,
                fontSize: 15,
                alignment: `left`,
                color: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            text: `Diagnosis:`,
            fontSize: 15,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            width: 10,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                text: props.items?.diagnosis === "" ? " " : props.items?.diagnosis,
                decoration: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                bold: false,
                decorationColor: ``,
                fontSize: 15,
              },
              {
                preserveLeadingSpaces: true,
                width: `auto`,
                decoration: ``,
                pageBreak: ``,
                decorationColor: ``,
                decorationStyle: ``,
                text: `.........................................................................................................................................................................................`,
                bold: false,
                alignment: `left`,
                color: ``,
                fontSize: 15,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      ...diagnosisCheck(props.items?.diagnosis),
      {
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        fontSize: 15,
      },
      {
        decoration: ``,
        bold: `true`,
        pageBreak: ``,
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        alignment: `left`,
        color: ``,
        text: `Travel Recommendation and Assessment`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 30,
            color: ``,
            text: ``,
            pageBreak: ``,
          },
          {
            image: props.items?.travelRecommend === "first" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            width: 5,
          },
          {
            bold: false,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            text: `Fit to fly as normal seated passenger`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            width: 30,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: ``,
          },
          {
            image: props.items?.travelRecommend === "second" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decoration: ``,
            width: 5,
            pageBreak: ``,
          },
          {
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `Fit to fly with non-medical escort/family`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            width: 30,
            alignment: `left`,
            text: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            image: props.items?.travelRecommend === "third" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            text: ``,
            alignment: `left`,
            width: 5,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            text: `Fit to fly with medical escort(s) only`,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 30,
          },
          {
            image: props.items?.travelRecommend === "fourth" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ` `,
            bold: false,
            alignment: `left`,
            decoration: ``,
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            decoration: `underline`,
            bold: `true`,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            text: `Not`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            text: ` Fit to fly / Travel only at patient’s own risk`,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        decoration: ``,
        color: ``,
        alignment: `left`,
        fontSize: 15,
        bold: false,
        text: ` `,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: `Special requirement(s) :`,
            width: `auto`,
            bold: false,
          },
          {
            fontSize: 15,
            text: ``,
            alignment: `left`,
            width: 10,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            stack: [
              {
                bold: false,
                decorationColor: ``,
                pageBreak: ``,
                text: props.items?.requireFirstLine
                  ? props.items?.requireFirstLine.length > 0
                    ? props.items?.requireFirstLine
                    : " "
                  : " ",
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decoration: ``,
                color: ``,
                width: `auto`,
              },
              {
                width: `auto`,
                pageBreak: ``,
                decoration: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                text: `...............................................................................................................................................................`,
                alignment: `left`,
                color: ``,
                bold: false,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        stack: [
          {
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            text: props.items?.requireSecondLine
              ? props.items?.requireSecondLine.length > 0
                ? props.items?.requireSecondLine
                : " "
              : " ",
            decorationStyle: ``,
            margin: [0, 0, 0, -15],
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
            width: `auto`,
          },
          {
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: `...............................................................................................................................................................................................................`,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
          },
        ],
      },
      {
        stack: [
          {
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            text: props.items?.requireThirdLine
              ? props.items?.requireThirdLine.length > 0
                ? props.items?.requireThirdLine
                : " "
              : " ",
            decorationStyle: ``,
            margin: [0, 0, 0, -15],
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
            width: `auto`,
          },
          {
            decorationColor: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: `...............................................................................................................................................................................................................`,
            width: `auto`,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            text: `Comment :`,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            decorationStyle: ``,
            width: 10,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                bold: false,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decorationStyle: ``,
                decorationColor: ``,
                color: ``,
                width: `auto`,
                alignment: `left`,
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
              },
              {
                decoration: ``,
                bold: false,
                decorationColor: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                fontSize: 15,
                width: `auto`,
                text: `......................................................................................................................................................................................`,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        pageBreak: ``,
        text: `...............................................................................................................................................................................................................`,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        width: `auto`,
        color: ``,
        bold: false,
        decorationStyle: ``,
      },
      {
        margin: [0, 0, 0, 0],
        width: `auto`,
        pageBreak: ``,
        bold: false,
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        color: ``,
        text: `...............................................................................................................................................................................................................`,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        text: ` `,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            bold: false,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `I hereby state that the patient is `,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            text: ` `,
            width: 5,
            color: ``,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            decoration: `underline`,
            alignment: `left`,
            pageBreak: ``,
            text: `FIT TO FLY`,
            decorationStyle: `dotted`,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: `true`,
            color: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            text: ` from`,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
          {
            width: 10,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            text: ``,
          },
          {
            stack: [
              {
                color: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                text: props.items?.flightDate ? props.items?.flightDate : ` `,
                bold: false,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decorationColor: ``,
                decorationStyle: ``,
                decoration: ``,
                alignment: `left`,
                width: `auto`,
              },
              {
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                fontSize: 15,
                pageBreak: ``,
                decoration: ``,
                text: `.............................................................................................................`,
                bold: false,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        width: `auto`,
        fontSize: 15,
        text: ` `,
        color: ``,
        bold: false,
        alignment: `left`,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `Physician’s Signature `,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            width: 30,
            decoration: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `.............................................................`,
            decoration: ``,
            color: ``,
            bold: false,
          },
          {
            alignment: `center`,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            width: 30,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            text: ` Medical License No. `,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
          },
          {
            bold: false,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 15,
          },
          {
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decoration: `underline`,
            color: ``,
            alignment: `center`,
            decorationStyle: `dotted`,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            width: 144,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `center`,
            width: `auto`,
            bold: false,
            text: `(${props.items?.doctorNameEn})`,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
    ],
    pageSize: `A4`,
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
